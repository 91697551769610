import { EntityBase } from "./entityBase";
import { ProductOption } from "./productOption";
import { TypeOption } from "./type.option";

export class Option extends EntityBase {
    public Name: string = '';
    public Priority: number;
    public ProductOptions: Array<ProductOption> = [];
    public Options: Array<Option> = [];
    public TypeOption: TypeOption;
    public TypeOptionId: number;
}