import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import * as routes from "../../common/routes";
import { IconHome } from "../../icons/icons";
import { IApplicationState } from "../../reducers/application.state";
import { AuthenticationState } from "../../reducers/authentication.slice";

export const LeftMenu: React.FC = () => {
    const AuthenticationState = useSelector<IApplicationState, AuthenticationState>((state) => state.authentication);

    {
        if (AuthenticationState.role === 'Admin') {
            return (
                <div className="menu__COMPONENT">
                    <div className="l__MENU">
                        <ul>
                            <li className="menu_TITLE">Заказы</li>
                            <li className="">
                                <NavLink to={routes.AllOrdersView} activeClassName="isSelected">
                                    <IconHome />
                                    <span> Заказы </span>
                                </NavLink>
                            </li>
                            <li className="menu_TITLE">Navigation</li>
                            <li className="hide">
                                <NavLink to={routes.DashboardView} activeClassName="isSelected">
                                    <IconHome />
                                    <span> Dashboard </span>
                                </NavLink>
                            </li>
                            <li className="">
                                <NavLink to={routes.AllProductsView} activeClassName="isSelected">
                                    <IconHome />
                                    <span> Продукты </span>
                                </NavLink>
                            </li>
                            <li className="">
                                <NavLink to={routes.ColorsView} activeClassName="isSelected">
                                    <IconHome />
                                    <span> Цвета </span>
                                </NavLink>
                            </li>
                            <li className="">
                                <NavLink to={routes.OptionsView} activeClassName="isSelected">
                                    <IconHome />
                                    <span> Опции </span>
                                </NavLink>
                            </li>

                            <li className="menu_TITLE">Пользователи</li>
                            <li className="">
                                <NavLink to={routes.AllUsersView} activeClassName="isSelected">
                                    <IconHome />
                                    <span>Пользователи</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            )
        } else if (AuthenticationState.role === 'Operator') {
            return (
                <div className="menu__COMPONENT">
                    <div className="l__MENU">
                        <ul>
                            <li className="menu_TITLE">Заказы</li>
                            <li className="">
                                <NavLink to={routes.AllOrdersView} activeClassName="isSelected">
                                    <IconHome />
                                    <span> Заказы </span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            )
        } else if (AuthenticationState.role === 'Content') {
            return (
                <div className="menu__COMPONENT">
                    <div className="l__MENU">
                        <ul>
                            <li className="menu_TITLE">Navigation</li>
                            <li className="">
                                <NavLink to={routes.AllProductsView} activeClassName="isSelected">
                                    <IconHome />
                                    <span> Продукты </span>
                                </NavLink>
                            </li>
                            <li className="">
                                <NavLink to={routes.ColorsView} activeClassName="isSelected">
                                    <IconHome />
                                    <span> Цвета </span>
                                </NavLink>
                            </li>
                            <li className="">
                                <NavLink to={routes.OptionsView} activeClassName="isSelected">
                                    <IconHome />
                                    <span> Опции </span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            )
        }
    }
}
