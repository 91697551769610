import React from "react"
import { LogoutOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../reducers/application.state";
import { UserProfile } from "../../entities/user/user.profile";
import { userManagementActions } from "../../reducers/user.management.slice";
import { setCookie } from "../../helpers/cookies.helper";

export const Header: React.FC = () => {
    const dispatch = useDispatch();

    const user = useSelector<IApplicationState, any>(state => state.authentication)

    const onLogOut = (event: any) => {
        setCookie('token', null, 0)
        window.location.href = '/';
    }

    return (
        <div className="header__COMPONENT">
            <div className="logo__CONTAINER">
                <img src="https://5print.ua/content/images/2/40088975188566.jpg" alt="logo" />
            </div>
            <div className="user">
                <span>
                    <span className="name">
                        {user.name}
                    </span>
                    <span className="email">
                        {user.email}
                    </span>
                </span>
                <div className="logoutOut" onClick={(event) => onLogOut(event)}>
                    <LogoutOutlined />
                </div>
            </div>
        </div>
    )
}
