import React from "react";
import { AllProductsView } from "./product/all.products.view";
import { Header } from "../component/header";
import { LeftMenu } from "../component/leftmenu";

export class DashboardView extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
    }

    public render() {
        return (
            <div className="dashboard__VIEW" style={{
                display: 'flex',
                justifyContent: 'center'
            }
            }>
               
            </div>
        )
    }
}