import { createSlice } from '@reduxjs/toolkit'
import { EnumModel } from '../entities/user/enum.model'
import { UserProfile } from '../entities/user/user.profile'

export type UserManagementState = {
    userRoles: string[],
    selectedUserProfile: UserProfile,
    selectedRole: EnumModel,
    usersList: UserProfile[],
    user: UserProfile
}

export const UserManagementState: UserManagementState = {
    userRoles: [],
    selectedUserProfile: new UserProfile(),
    selectedRole: null,
    usersList: [],
    user: new UserProfile()
}

const userManagementSlice = createSlice({
    name: 'userManagement',
    initialState: UserManagementState,
    reducers: {
        setUsersList(state, action) { state.usersList = action.payload },
        setUser(state, action) { state.user = action.payload },
        setUserProfile(state, action) { state.selectedUserProfile = action.payload; },
        apiGetSelectedRole() { },
        setRole(state, action) { state.selectedRole = action.payload },
        apiGetUsers() {},
        apiGetUsersByCompanyId(state, action) { },
        apiGetRoles(state) { },
        setRoles(state, action) { state.userRoles = action.payload },
        apiGetUserProfileByNetId(state, action) { },
        apiNewUser(state, action) { },
        apiUpdateUser(state, action) { },
        apiRemoveUser(state, action) { },
        apiChangeUserPassword(state, action) { },
        resetUserManagementStore(state) {
            return UserManagementState;
        }
    },
})

export const userManagementActions = userManagementSlice.actions
export default userManagementSlice.reducer
