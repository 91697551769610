import React from "react";
import * as API from "../../../constants/api.constants";
import { IconClose } from "../../../icons/icons";

export class RemoveCategoryView extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div className="editControl__MODAL">
                <div className="modal__FRAME">
                    <div className="modal__CLOSE" onClick={() => this.props.onCloseModalRemoveCategory()}>
                        <IconClose />
                    </div>
                    <div className="modal__HEADER">
                        Удаление категории
                    </div>
                    <div className="modal__BODY">
                        <h3>
                            {this.props.removeCategory.Name}
                        </h3>

                        <div className="default__CONTROL" onClick={(event: any) => this.props.onCategoryChangeCommand('remove')}>Удалить</div>
                    </div>
                </div>
            </div>
        )
    }
}