import React from "react";
import * as API from "../../../constants/api.constants";
import { IconClose } from "../../../icons/icons";

export class EditCategoryView extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div className="editControl__MODAL">
                <div className="modal__FRAME">
                    <div className="modal__CLOSE" onClick={() => this.props.onCloseModalEditCategory()}>
                        <IconClose/>
                    </div>
                    <div className="modal__HEADER">
                        Редактирование
                    </div>
                    <br />
                    <div className="modal__BODY">
                        <input type="text"
                            placeholder="Название"
                            value={this.props.editCategory.Name}
                            onChange={(event: any) => this.props.onValueChangeEditCategoryName(event)}
                        />
                        <div className="default__CONTROL" onClick={(event: any) => this.props.onCategoryChangeCommand('edit')}>Изменить</div>
                    </div>
                </div>
            </div>
        )
    }
}