import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as API from "../../../constants/api.constants";
import { Order } from "../../../models/order";
import { IApplicationState } from "../../../reducers/application.state";
import { orderActions } from "../../../reducers/order.slice";
import { Table, Tag, Space, Button } from 'antd';
import Moment from 'moment';
import { SnippetsOutlined } from '@ant-design/icons';
import { NavLink } from "react-router-dom";
import * as routes from "../../../common/routes";


export const AllOrdersView: React.FC = (props: any) => {
    const dispatch = useDispatch();

    const orders = useSelector<IApplicationState, Order[]>(state => state.order.orderList)
    console.log(orders)

    useEffect(() => {
        if (orders.length === 0) {
            dispatch(orderActions.apiGetOrdersAll())
        }
       
    }, [orders])

    useEffect(() => {
        return () => {
            dispatch(orderActions.setOrderList([]))
             
        }
    }, [])

    const getHeight = () => {
        let h = document.getElementsByClassName('price_LVL__CONTAINER')[0];
        if (h) {
            return h.clientHeight - 100
        } else {
            return 600
        }
    }

    const columns = [
        {
            title: '№',
            dataIndex: 'Index',
            key: 'Index',
            render: text => <a>{text}</a>,
            width: 100,
        },
        {
            title: 'Создан',
            dataIndex: 'Created',
            key: 'Created',
            render: Created => <div>{Moment(Created).format('DD-MM-YYYY, HH:MM')}</div>,
            width: 140,
        },
        {
            title: 'Клиент',
            dataIndex: 'Client',
            key: 'Client',
            render: client => <div>{client.FirstName}</div>,
        },
        {
            title: 'Телефон',
            dataIndex: 'Client',
            key: 'Client',
            render: client => <div>{client.PhoneNumber}</div>,
        },
        {
            title: 'Email',
            dataIndex: 'Client',
            key: 'Client',
            render: client => <div>{client.Email}</div>,
        },
        {
            title: 'Цена',
            dataIndex: 'TotalPrice',
            key: 'TotalPrice',
            render: price => <div>{price} грн</div>,
            width: 160,
        },
        {
            title: 'Оператор',
            dataIndex: 'UserModel',
            key: 'UserModel',
            render: user => <div>{user ? user.Name :' --- '}</div>,
            width: 200,
        },
        {
            title: ' ',
            dataIndex: 'Id',
            key: 'Id',
            render: id => <NavLink to={`${routes.AllOrdersView}/order/${id}`}>Подробние</NavLink>,
            width: 120,
        }
    ];

    return (
        <div className="allOrders__PAGE">
            <div className="price_LVL__CONTAINER">
                <Table
                    columns={columns}
                    dataSource={orders}
                    size={"small"}
                    pagination={{ pageSize: 20 }}
                    scroll={{ y: getHeight() }}
                />

            </div>
        </div>
    )
}
