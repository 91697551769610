import { Button, Popconfirm } from "antd";
import React from "react";
import * as API from "../../../constants/api.constants";
import { IconFolder, IconSettings, IconClose } from "../../../icons/icons";
import { Option } from "../../../models/option";
import { TypeOption } from "../../../models/type.option";
import { NewSubOptionView } from "./new.sub.option.view";
import { DeleteOutlined } from '@ant-design/icons';
import { getCookie } from "../../../helpers/cookies.helper";

export class OptionType extends React.Component<any, any>{

    constructor(props: any) {
        super(props);

        this.state = {
            optionTypeList: [],
            optionName: '',
            optionEditName: '',
            selectedOption: Option,
            isSelectedRemoveOptionModal: false,
            modalNewSubOption: false,
            allOptionsSelectedType: []
        }

        this.getAllOptions();
    }

    public getAllOptions() {
        let getAllCotUrl = API.API_GET_ALL_OPTIONS_TYPE;
        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;

        fetch(getAllCotUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AC,
            },
            method: "GET",

        }).then(response => response.json())
            .then(data => {
                if (data.StatusCode === 200) {

                    this.setState({
                        optionTypeList: data.Body
                    })
                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_GET_ALL_OPTIONS_TYPE"));
    }


    public onValueChangeOptionName(event: any) {
        this.setState({
            optionName: event.target.value
        })
    }

    public onValueChangeOptionEditName(event: any) {
        this.setState({
            optionEditName: event.target.value
        })
    }

    public onChangeOption(command) {

        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;
        let changeBodyOption: any;


        switch (command) {
            case 'new': {
                changeBodyOption = {
                    "Name": this.state.optionName
                }
                break;
            }

            case 'edit': {
                changeBodyOption = {
                    Name: this.state.optionEditName,
                    Id: this.state.selectedOption.Id,
                }
                break;
            }

            case 'remove': {
                changeBodyOption = {
                    Deleted: true,
                    Id: this.state.selectedOption.Id
                }
                break;
            }
        }

        fetch(API.API_POST_CHANGE_OPTIONS_TYPE, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AC,
            },
            method: "POST",
            body: JSON.stringify(changeBodyOption),
        }).then(response => response.json())
            .then(data => {
                if (data.StatusCode === 200) {
                    this.setState({
                        optionTypeList: data.Body,
                        optionName: '',
                        isSelectedRemoveOptionModal: false
                    })

                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_POST_CHANGE_OPTIONS_TYPE"));
    }

    public onSelectOption(option: Option) {
        this.setState({
            selectedOption: option,
            optionEditName: option.Name,
        })
    }

    public onCreateSubOption() {
        this.setState({
            modalNewSubOption: true
        })
    }

    public isOpenModalRemoveOption() {
        this.setState({
            isSelectedRemoveOptionModal: !this.state.isSelectedRemoveOptionModal
        })
    }

    public onOpenCloseModalNewSubOption() {
        this.setState({
            modalNewSubOption: !this.state.modalNewSubOption
        })
        this.getAllOptions();
    }

    public removeOption(selectedOption: Option) {
         
        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;

        let changeBodyOption = {
            Deleted: true,
            Id: selectedOption.Id,
            TypeOptionId: selectedOption.TypeOptionId
        };

        fetch(API.API_POST_CHANGE_OPTIONS, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AC,
            },
            method: "POST",
            body: JSON.stringify(changeBodyOption),
        }).then(response => response.json())
            .then(data => {
                 
                if (data.StatusCode === 200) {
                    this.setState({
                        isSelectedRemoveOptionModal: false
                    })
                    this.getAllOptions();
                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_POST_CHANGE_OPTIONS_TYPE"));
    }

    public render() {
        return (
            <div className="options__PAGE">
                <div className="colorList__CONTAINER ">
                    <div className="page__TITLE">
                        <span>Опции</span>
                    </div>

                    <div className="newColor__SECTION">
                        <ul className="t_LINE">
                            <li className="color__ROW_INFO">
                                <div className="l__ICON">
                                    <IconFolder />
                                </div>
                                <div className="categories__INFO_P">
                                    <p className="category__NAME_T">создание</p>
                                    <p className="category__NAME_B">Добавление опции</p>
                                </div>

                                <div className="product__NAME_P">
                                    <div className="product__NAME_ROW">
                                        <div className="input__CONTROL">
                                            <p className="product__NAME_T">название опции</p>
                                        </div>
                                        <div className="input__CONTROL">
                                            <input className="input__DEFAULT" type="text"
                                                onChange={(event: any) => this.onValueChangeOptionName(event)}
                                                value={this.state.optionName}
                                            />
                                        </div>

                                        <div className="button__CONTROL">
                                            <div className="changeProductName__BUTTON" onClick={(event: any) => this.onChangeOption('new')}>добавить</div>
                                        </div>
                                    </div>
                                </div>

                            </li>

                            <li className="colors__ROW_INFO">
                                <div className="l__ICON">
                                    <IconSettings />
                                </div>
                                <div className="colors__FRAME">
                                    <div className="allColors__LIST">
                                        <div className="list__BODY">
                                            {
                                                this.state.optionTypeList ? this.state.optionTypeList.filter(x => !x.IsColor).map((option: Option, index: number) =>
                                                    <div className="color__ITEM hide" key={index} onClick={(event: any) => this.onSelectOption(option)}>
                                                        <span className="color_CI"></span>
                                                        {option.Name ? option.Name : 'Error - colorList[color.name]'}
                                                    </div>
                                                ) : null
                                            }
                                            {
                                                this.state.optionTypeList ? this.state.optionTypeList.filter(x => !x.IsColor).map((option: Option, index: number) =>
                                                    <details className="color__ITEM2" key={index} onClick={(event: any) => this.onSelectOption(option)}>
                                                        <summary>
                                                            {option.Name ? option.Name : 'Error - colorList[color.name]'}

                                                        </summary>

                                                        <div>
                                                            {option.Options ? option.Options.map((subOption: Option, key: number) =>
                                                                <div className="color__ITEM rem__ICON" key={key}>
                                                                    <span className="color_CI"></span>
                                                                    {subOption.Name}
                                                                    <Popconfirm placement="topLeft" title={'Удалить?'} onConfirm={() => this.removeOption(subOption)} okText="Да" cancelText="Нет">
                                                                        <div className="ico">
                                                                            <DeleteOutlined />
                                                                        </div>
                                                                    </Popconfirm>

                                                                </div>
                                                            ) : null}
                                                        </div>

                                                    </details>
                                                ) : null
                                            }

                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>

                {this.state.optionEditName &&
                    <div className="selectedColor__CONTAINER_R">
                        <div className="page__TITLE">
                            <span>Редактирование</span>
                            <div className="add__CONTROL" onClick={() => this.onCreateSubOption()}>добавить саб опцию</div>
                        </div>

                    <div className="editingColor__CONTAINER card__DEFAULT" style={{ paddingTop: '10px' }}>
                            <div className="input__CONTROL">
                                <p className="product__NAME_T">название опции</p>
                            </div>
                            <div className="input__CONTROL">
                                <input
                                    className="input__DEFAULT"
                                    value={this.state.optionEditName}
                                    onChange={(event: any) => this.onValueChangeOptionEditName(event)}
                                    type="text" />


                            </div>

                            <div className="editing__CONTROLS">
                                <div className="editing__CONTROL" onClick={(event: any) => this.onChangeOption('edit')}>
                                    изменить
                                </div>
                            </div>

                            <p>
                                При удалении опции, в старых заказах она останется.
                                <br />
                                <span onClick={(event: any) => this.isOpenModalRemoveOption()}>Удалить?</span>
                            </p>
                        </div>
                    </div>
                }

                {
                    this.state.isSelectedRemoveOptionModal ?

                        <div className="editControl__MODAL">
                            <div className="modal__FRAME">
                                <div className="modal__CLOSE" onClick={() => this.isOpenModalRemoveOption()}>
                                    <IconClose />
                                </div>

                                <div className="modal__HEADER">Удаление опции</div>
                                <div className="modal__BODY">
                                    <h3>
                                        {this.state.optionEditName}
                                    </h3>

                                    <div className="default__CONTROL" onClick={(event: any) => this.onChangeOption('remove')}>Удалить</div>
                                </div>
                            </div>
                        </div> : null
                }

                {
                    this.state.modalNewSubOption ?
                        <NewSubOptionView
                            selectedOption={this.state.selectedOption}
                            onOpenCloseModalNewSubOption={this.onOpenCloseModalNewSubOption.bind(this)}
                        />
                        : null
                }
            </div>
        )
    }
}