import React from "react";
import * as API from "../../../constants/api.constants";
import { Option } from "../../../models/option";
import { IconFolder, IconSettings, IconRemove, IconEdit, IconClose } from "../../../icons/icons";
import { TypeOption } from "../../../models/type.option";
import { getCookie } from "../../../helpers/cookies.helper";

interface IState {
    colorList: Option[];
    colorName: string;
    colorPriority: number;
    selectedColor: Option;
    colorEditName: string;
    colorEditPriority: number;
    isSelectedRemoveColorModal: boolean;
    allTypeOptions: TypeOption;
}

export class ColorsView extends React.Component<any, IState>{
    constructor(props: any) {
        super(props);

        this.state = {
            colorList: [],
            colorName: '',
            colorPriority: 0,
            selectedColor: new Option(),
            colorEditName: '',
            colorEditPriority: 0,
            isSelectedRemoveColorModal: false,
            allTypeOptions: new TypeOption()
        }

        this.getAllColorsCommand();
    }

    public getAllColorsCommand() {
        let getAllCotUrl = API.API_GET_ALL_OPTIONS_COLORS;
        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;

        fetch(getAllCotUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AC,
            },
            method: "GET",

        }).then(response => response.json())
            .then(data => {
                if (data.StatusCode === 200) {
                    this.setState({
                        allTypeOptions: data.Body
                    })
                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_GET_ALL_COLORS"));
    }

    public onSelectColor(selectedColor: Option) {
        this.setState({
            selectedColor: selectedColor,
            colorEditName: selectedColor.Name,
            colorEditPriority: selectedColor.Priority
        })
    }

    public isOpenModalRemoveColor() {
        this.setState({
            isSelectedRemoveColorModal: !this.state.isSelectedRemoveColorModal
        })
    }

    public onChangeColor(command) {

        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;
        let changeBodyOption: any;


        switch (command) {
            case 'new': {
                changeBodyOption = {
                    Name: this.state.colorName,
                    Priority: this.state.colorPriority,
                    "TypeOption": {
                        "Id": this.state.allTypeOptions.Id
                    }
                }
                break;
            }

            case 'edit': {
                changeBodyOption = {
                    Name: this.state.colorEditName,
                    Priority: this.state.colorEditPriority,
                    Id: this.state.selectedColor.Id,
                    TypeOptionId: this.state.allTypeOptions.Id
                }
                break;
            }

            case 'remove': {
                changeBodyOption = {
                    Deleted: true,
                    Id: this.state.selectedColor.Id
                }
                break;
            }
        }

        fetch(API.API_POST_CHANGE_OPTIONS, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AC,
            },
            method: "POST",
            body: JSON.stringify(changeBodyOption),
        }).then(response => response.json())
            .then(data => {
                  
                if (data.StatusCode === 200) {
                    this.setState({
                        allTypeOptions: data.Body,
                        colorName: '',
                        colorPriority: 0,
                        isSelectedRemoveColorModal: false,
                        colorEditName: '',
                        colorEditPriority: 0
                    })
                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_POST_CHANGE_OPTIONS"));
    }

    public onValueChangeColorName(event: any) {
        this.setState({
            colorName: event.target.value
        })
    }

    public onValueChangeColorEditName(event: any) {
        this.setState({
            colorEditName: event.target.value
        })
    }

    public onValuedChangeColorPriority(event: any) {
        this.setState({
            colorPriority: Number.parseInt(event.target.value)
        })
    }

    public onValuedChangeColorEditPriority(event: any) {
        this.setState({
            colorEditPriority: Number.parseInt(event.target.value)
        })
    }

    render() {
        return (
            <div className="colors__PAGE">
                <div className="colorList__CONTAINER ">
                    <div className="page__TITLE">
                        <span>Цвета</span>
                    </div>

                    <div className="newColor__SECTION">
                        <ul className="t_LINE">
                            <li className="color__ROW_INFO">
                                <div className="l__ICON">
                                    <IconFolder />
                                </div>
                                <div className="categories__INFO_P">
                                    <p className="category__NAME_T">создание</p>
                                    <p className="category__NAME_B">Добавление цвета</p>
                                </div>

                                <div className="product__NAME_P">
                                    <div className="product__NAME_ROW">
                                        <div className="input__GROUP">
                                            <div className="input__CONTROL">
                                                <p className="product__NAME_T">название цвета</p>
                                            
                                                <input className="input__DEFAULT" type="text"
                                                    value={this.state.colorName}
                                                    onChange={(event: any) => this.onValueChangeColorName(event)}
                                                />
                                            </div>

                                            <div className="input__CONTROL pri">
                                                <p className="product__NAME_T">приоритет цвета</p>
                                            
                                                <input
                                                    className="input__DEFAULT"
                                                    value={this.state.colorPriority}
                                                    onChange={(event: any) => this.onValuedChangeColorPriority(event)}
                                                    type="number" />

                                            </div>
                                        </div>

                                        <div className="button__CONTROL">
                                            <div className="changeProductName__BUTTON" onClick={(event: any) => this.onChangeColor('new')}>добавить</div>
                                        </div>
                                    </div>
                                </div>

                            </li>

                            <li className="colors__ROW_INFO">
                                <div className="l__ICON">
                                    <IconSettings />
                                </div>
                                <div className="colors__FRAME">
                                    <div className="allColors__LIST">
                                        <div className="list__BODY">
                                            
                                            {
                                                this.state.allTypeOptions && this.state.allTypeOptions.Options ? this.state.allTypeOptions.Options.map((color: Option, index: number) =>
                                                    <div className="color__ITEM" key={index} onClick={(event: any) => this.onSelectColor(color)}>
                                                        <span className="color_CI"></span>
                                                        {color.Name ? color.Name : 'Error - colorList[color.name]'}
                                                    </div>
                                                ) : null
                                            }
                                             
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>

                {this.state.selectedColor.Name &&
                    <div className="selectedColor__CONTAINER_R">
                        <div className="page__TITLE">
                            <span>Редактирование</span>
                        </div>

                        <div className="editingColor__CONTAINER card__DEFAULT">
                            <div className="input__CONTROL">
                                <p className="product__NAME_T">название цвета</p>
                            </div>
                            <div className="input__CONTROL">
                                <input
                                    className="input__DEFAULT"
                                    value={this.state.colorEditName}
                                    onChange={(event: any) => this.onValueChangeColorEditName(event)}
                                    type="text" />


                            </div>

                            <div className="input__CONTROL">
                                <p className="product__NAME_T">приоритет цвета</p>
                            </div>
                            <div className="input__CONTROL">
                                <input
                                    className="input__DEFAULT"
                                    value={this.state.colorEditPriority}
                                    onChange={(event: any) => this.onValuedChangeColorEditPriority(event)}
                                    type="number" />

                            </div>

                            <div className="editing__CONTROLS">
                                <div className="editing__CONTROL" onClick={(event: any) => this.onChangeColor('edit')}>
                                    изменить
                                </div>
                            </div>

                            <p>
                                При удалении опции(цвета), в старых заказах он останется.
                                <br />
                                <span onClick={(event: any) => this.isOpenModalRemoveColor()}>Удалить?</span>
                            </p>
                        </div>
                    </div>
                }

                {
                    this.state.isSelectedRemoveColorModal ?

                        <div className="editControl__MODAL">
                            <div className="modal__FRAME">
                                <div className="modal__CLOSE" onClick={() => this.isOpenModalRemoveColor()}>
                                    <IconClose />
                                </div>

                                <div className="modal__HEADER">Удаление цвета</div>
                                <div className="modal__BODY">
                                    <h3>
                                        {this.state.colorEditName}
                                    </h3>

                                    <div className="default__CONTROL" onClick={(event: any) => this.onChangeColor('remove')}>Удалить</div>
                                </div>
                            </div>
                        </div> : null
                }
            </div>
        )
    }
}