export class API {
   // public static readonly SERVER_URL = 'http://78.152.175.67:15010'
    public static readonly SERVER_URL = 'https://server.5print.ua'

    public static readonly API_PART = '/'

    public static readonly UserIdentityEndPoints: any = {
        SIGN_IN: API.API_PART + 'authentification/token/get?login=',
        GET_ALL_USERS: API.API_PART + 'users/all/get',
        GET_ALL_ROLES: API.API_PART + 'users/roles/all/get',
        NEW_USER_PROFILE: API.API_PART + 'users/new',
        UPDATE_USER_PROFILE: API.API_PART + 'users/update',
        CHANGE_PASSWORD: API.API_PART + 'users/password/change',
        REMOVE_USER: API.API_PART + 'users/remove?id='
    }
}

export default API;



//export const API_SERVER = 'http://78.152.175.67:15010/';
export const API_SERVER = 'https://server.5print.ua/';

//AUTH
export const API_GET_AUTH = API_SERVER + "authentification/token/get?";
//
export const API_GET_ALL_CATEGORIES = API_SERVER + 'categories/all/get';
export const API_POST_CHANGE_CATEGORY = API_SERVER + 'categories/manage';
export const API_GET_CATEGORY_BY_ID = API_SERVER + 'categories/products/get?id=';
export const API_GET_PRODUCT_BY_ID = API_SERVER + 'products/get?id=';
export const API_POST_CHANGE_PRODUCT = API_SERVER + 'products/manage';
export const API_POST_CHANGE_IMG_TO_PRODUCT = API_SERVER + 'products/image/add?id=';
export const API_POST_CHANGE_OPTIONS = API_SERVER + 'options/manage';
export const API_POST_CHANGE_OPTIONS_TYPE = API_SERVER + 'options/type/manage';
export const API_GET_ALL_OPTIONS = API_SERVER + 'options/get?type=';

export const API_GET_ALL_OPTIONS_TYPE = API_SERVER + 'options/type/all/get';
export const API_GET_ALL_OPTIONS_COLORS = API_SERVER + 'options/type/color/get';

export const API_GET_CHANGE_COLORS = API_SERVER + 'options/color/manage';
export const API_POST_CHANGECOLOR_TO_PRODUCT = API_SERVER + 'product/options/manage';
export const API_POST_CHANGE_VISIBLE_TO_PRODUCT = API_SERVER + 'products/visible/update?id=';

///
export const API_GET_ALL_ORDERS = API_SERVER + 'orders/all/get';
export const API_GET_ORDER = API_SERVER + 'orders/get?id=';
export const API_GET_ORDER_ZIP = API_SERVER + 'orders/images/archive/get?id=';
export const API_CHANGE_USER_FOR_ORDER = API_SERVER + 'orders/user/add?id=';
export const API_CHANGE_ORDER_STATUS = API_SERVER + 'orders/type/status/update?id=';