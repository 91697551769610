import { createSlice } from '@reduxjs/toolkit'
import { UserProfile } from '../entities/user/user.profile';
import { Order } from '../models/order';
import { OrderItem } from '../models/order.item';

export type OrderTypeState = {
    order: Order,
    orderList: Order[],
    selectedOrderItem: OrderItem,
    orderZipUrl: string,
    orderUserId: string,
    changeOrderStatusValue: any
}

export const OrderState: OrderTypeState = {
    order: new Order(),
    orderList: [],
    selectedOrderItem: new OrderItem(),
    orderZipUrl: '',
    orderUserId: '',
    changeOrderStatusValue: undefined
}

const orderSlice = createSlice({
    name: 'order',
    initialState: OrderState,
    reducers: {
        apiGetOrderById(state, action) { },
        apiGetOrderItemById(state, action) { },
        apiGetOrdersAll() { },
        apiGetOrderZip(state, action) { },
        setOrderItem(state, action) {
            state.selectedOrderItem = action.payload
        },
        apiOrderUserId(state, action) {
            state.orderUserId = action.payload
        },
        apiChangeOrderStatus(state, action) {
            state.changeOrderStatusValue = action.payload
        },
        setOrder(state, action) {
            state.order = action.payload
        },
        setOrderList(state, action) {
            state.orderList = action.payload
        },
        setOrderZip(state, action) {
            state.orderZipUrl = action.payload
        },
    }
})

export const orderActions = orderSlice.actions;
export default orderSlice.reducer;