import React from "react";
import * as API from "../../../constants/api.constants";
import { getCookie } from "../../../helpers/cookies.helper";
import { IconClose } from "../../../icons/icons";
import { Option } from "../../../models/option";

export class NewSubOptionView extends React.Component<any, any>{
    constructor(props: any) {
        super(props);

        this.state = {
            optionName: ''
        }
    }

    public createOption() {
        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;

        let changeBodyOption = {
            "Name": this.state.optionName,
            "TypeOption": {
                "Id": this.props.selectedOption.Id
            }
        }

        fetch(API.API_POST_CHANGE_OPTIONS, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AC,
            },
            method: "POST",
            body: JSON.stringify(changeBodyOption),
        }).then(response => response.json())
            .then(data => {
                if (data.StatusCode === 200) {
                     
                    this.setState({
                        optionName:''
                    })
                    this.props.onOpenCloseModalNewSubOption();
                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_POST_CHANGE_OPTIONS_TYPE"));
    }

    public onValueChangeOptionEditName(event: any) {
        this.setState({
            optionName: event.target.value
        })
    }

    render() {
        return (
            <div className="newControl__MODAL">
                <div className="modal__FRAME">
                    <div className="modal__CLOSE" onClick={() => this.props.onOpenCloseModalNewSubOption()}>
                        <IconClose />
                    </div>
                    <div className="modal__HEADER">
                        Создание опции для <b>{this.props.selectedOption.Name}</b>
                    </div>
                    <br />
                    <div className="modal__BODY">
                        <input type="text"
                            placeholder="Название"
                            value={this.state.optionName}
                            onChange={(event: any) => { this.onValueChangeOptionEditName(event) }}
                        />
                        <div className="new__CONTROL" onClick={() => this.createOption()}>Сохранить</div>
                    </div>
                </div>
            </div>
        )
    }
}