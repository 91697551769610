import { Avatar, Button, Card, Descriptions, Select, Statistic } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import * as API from "../../../constants/api.constants";
import { Option } from "../../../models/option";
import { Order } from "../../../models/order";
import { OrderItem } from "../../../models/order.item";
import { ProductOption } from "../../../models/productOption";
import { IApplicationState } from "../../../reducers/application.state";
import { orderActions } from "../../../reducers/order.slice";
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { NavLink } from "react-router-dom";
import { CanvaText } from "../../../models/canvas.text";

export const PreviewOrderView: React.FC = () => {
    const dispatch = useDispatch();
    const uriParams: { id } = useParams()
    const orderId: string = uriParams.id;

    const order = useSelector<IApplicationState, Order>(state => state.order.order)
    const orderZip = useSelector<IApplicationState, string>(state => state.order.orderZipUrl)

    useEffect(() => {
        if (order.Id === 0) {
            dispatch(orderActions.apiGetOrderById(orderId))
        } else {
            console.log(order)
        }

    }, [order])

    useEffect(() => {
        return () => {
            dispatch(orderActions.setOrderZip(''))
            dispatch(orderActions.setOrder(new Order()))
        }
    }, [])

    const parseCanvas = (canvas: any) => {
        let can = JSON.parse(canvas)
        let textArr = can.objects.map((text: any, i: number) => {

            if (text.type === 'i-text') {
                return ({
                    value: text.text,
                    fontSize: text.fontSize,
                    fontWeight: text.fontWeight,
                    fill: text.fill,
                    fontFamily: text.fontFamily,
                })
            } else {
                return null
            }
        })


        return textArr
    }

    return (
        <div className="orderItem__PAGE">
            <div className="orderList__COMPONENT">
                {order.OrderItems.map((orderItem: OrderItem, index: number) =>
                    <div className="product_LIST__ITEM" key={index}>
                        <div className="item__CARD">
                            <div className="item__BOX">
                                <div className="box__IMG">
                                    <div className="image__WRAPPER">
                                        <div className="image__BOX">
                                            <div className="svgIMG__preview" dangerouslySetInnerHTML={{ __html: orderItem.Preview }}></div>
                                            { /*{productItem.Image && <img src={productItem.Image.ImageUrl} />}*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="box__INFO">
                                    <div className="info__NAME">
                                        <span>Наименование</span>
                                        {orderItem.Product.Name}
                                        {' '}
                                        {' '}
                                         ({orderItem.Quantity} шт. / {orderItem.Price} грн.)
                                    </div>
                                    <div className="info__OPTIONS">
                                        {orderItem.OrderItemProductOptions.map((option: any, i: number) => {
                                            if (option.ProductOption.Option.TypeOption.Name !== 'Color') {
                                                return <span key={i} style={{ paddingRight: 10 }}>
                                                    {option.ProductOption.Option.TypeOption.Name}: <b> {option.ProductOption.Option.Name}</b>
                                                </span>
                                            }
                                        }

                                        )}
                                    </div>
                                    <div className="info__OPTIONS">
                                        <Descriptions
                                            bordered
                                            title=" "
                                            size={'small'}

                                        >
                                            {parseCanvas(orderItem.Canvas).map((canvasText: CanvaText, j: number) => {
                                                if (canvasText !== null) {
                                                    return <React.Fragment key={j}>
                                                        <Descriptions.Item label="Текст">{canvasText.value}</Descriptions.Item>
                                                        <Descriptions.Item label="Шрифт">{canvasText.fontFamily}</Descriptions.Item>
                                                        <Descriptions.Item label="Цвет">
                                                            <Avatar shape="square" size="small" style={{ backgroundColor: canvasText.fill }} />
                                                            {' '}
                                                            {canvasText.fill}
                                                        </Descriptions.Item>
                                                    </React.Fragment>
                                                }
                                            }
                                            )}
                                        </Descriptions>
                                    </div>

                                    { /*
                                      <NavLink className="info__CREATE" to={`${routes.PRODUCT_URI}${productItem.Id}`}>
                                        Создать
                                            </NavLink>
                                     */}

                                </div>
                            </div>
                        </div>
                    </div>


                )}
            </div>
            <div className="orderClient__COMPONENT" style={{ padding: 20 }}>
                <Card style={{ marginBottom: 20 }}>
                    {console.log(order)}

                    {
                        order && order.Id > 0 && <Select
                            defaultValue={order && order.Id > 0 && order.Status}
                            style={{ width: '100%', marginBottom: 20 }}
                            onChange={(value) => {
                                dispatch(orderActions.apiChangeOrderStatus({ id: orderId, status: value }))
                            }}>
                            <Select.Option value={0}>Новый</Select.Option>
                            <Select.Option value={1}>В ожидании</Select.Option>
                            <Select.Option value={2}>Принят</Select.Option>
                        </Select>
                    }

                    <Button type="primary" style={{ backgroundColor: order.UserModel ? "green" : 'red' }} block onClick={() => dispatch(orderActions.apiOrderUserId(order.Id))}>
                        Принять заказ
                    </Button>
                </Card>

                <Card>
                    <Statistic
                        title="Цена"
                        value={order.TotalPrice}
                        precision={2}
                        valueStyle={{ color: '#3f8600' }}
                        prefix={<ArrowUpOutlined />}
                        suffix="грн"
                    />
                </Card>
                <Card style={{ marginTop: 20 }}>
                    <div className="iRow">
                        <div className="title">
                            Имя
                    </div>
                        <div className="value">
                            {order.Client && order.Client.FirstName}
                        </div>
                    </div>

                    <div className="iRow">
                        <div className="title">
                            Номер телефона
                    </div>
                        <div className="value">
                            {order.Client && order.Client.PhoneNumber}
                        </div>
                    </div>

                    <div className="iRow">
                        <div className="title">
                            Email
                    </div>
                        <div className="value">
                            {order.Client && order.Client.Email}
                        </div>
                    </div>
                </Card>



                <Card style={{ marginTop: 20 }}>
                    {orderZip === '' ?
                        <Button type="primary" block onClick={() => dispatch(orderActions.apiGetOrderZip(order.Id))}>
                            Сформировать архив
                        </Button> :
                        <a className="ant-btn ant-btn-primary ant-btn-block" target={'blank'} href={`${orderZip}`} download>
                            Скачать ?
                        </a>
                    }

                </Card>
            </div>

        </div>
    )
}