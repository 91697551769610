import React, { useEffect, useState } from "react"
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { FormicReference, IAuthentication } from "./security.interfaces";
import * as Yup from 'yup'
import { IApplicationState } from "../../reducers/application.state";
import { authenticationActions } from "../../reducers/authentication.slice";
import { Button } from "antd";

export const SignIn: React.FC = () => {
    const dispatch = useDispatch();

    const fetching = useSelector<IApplicationState, any>((state) => state.authentication.fetching)

    useEffect(() => {
        document.onkeydown = (event) => {
            if ((event as any).keyCode === 13) {
                event.preventDefault()
                formikReference.formik.submitForm()
            }
        }

        return () => {
            document.onkeydown = null
        }
    })

    const [formikReference] = useState<FormicReference>(
        new FormicReference(() => { })
    )

    const errorMessage = useSelector<IApplicationState, string>(
        (state) => state.authentication.message
    )

    const isErrorMessage = useSelector<IApplicationState, boolean>(
        (state) => state.authentication.isErrorMessage
    )

    const [isActiveForm, setIsActiveForm] = useState(false)

    useEffect(() => {
        if (errorMessage) {
            setIsActiveForm(false)
        }
    }, [errorMessage])


    const SignInSchema = Yup.object().shape({
        email: Yup.string()
            .email(() => 'Email incorrect')
            .required(() => 'Email Required'),
        password: Yup.string()
            .min(8, () => 'Password must contain at least 8 characters')
            .required(() => 'Password Required'),
    })


    const handleClearErrorMessages = () =>
        errorMessage && dispatch(authenticationActions.clearMessage())

    return (
        <Formik

            initialValues={{
                email: 'admin@gmail.com',
                password: 'AdminPassword'
            }}
            innerRef={(formik: any) => {
                formikReference.formik = formik
            }}
            validationSchema={SignInSchema}
            onSubmit={(values) => {

                handleClearErrorMessages()
                const data: IAuthentication = {
                    Email: values.email,
                    Password: values.password
                }
                 
                dispatch(authenticationActions.api_requestToken(data))

            }}
        >
            {(formik) => (
                <Form className="view__CONTENT">
                    <div className="login__PAGE">
                        <div className="login__FORM_CONTAINER">
                            <Field
                                name="email"
                                type="email"
                                spellCheck="false"
                                className="ant-input ant-input-lg"
                                autoComplete="off"
                            />
                            {formik.errors.email && formik.touched.email ? (
                                <span className="form_valMessage">
                                    {formik.errors.email}
                                </span>
                            ) : null}

                            <Field
                                name="password"
                                type="password"
                                spellCheck="false"
                                className="ant-input ant-input-lg"
                                autoComplete="new-password"

                            />
                            {formik.errors.password && formik.touched.password ? (
                                <span className="form_valMessage">
                                    {formik.errors.password}
                                </span>
                            ) : null}
                            <Button size={"large"} type="primary" htmlType="submit" className="login_CONTROL">Sign In</Button>

                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}