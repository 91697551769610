export const MasterView = "/app";
export const AuthView = "/auth";
export const DashboardView = MasterView + "/dashboard";
//export const DashboardView = "/";
export const AllProductsView = MasterView + "/products";
export const ColorsView = MasterView + "/colors";
export const OptionsView = MasterView + "/options";
export const AllOrdersView = MasterView + "/orders";
export const AllUsersView = MasterView + '/users';

