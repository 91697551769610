import React from "react";
import * as API from "../../../constants/api.constants";
import { IconArrow, IconRemove, IconEdit, IconPhoto } from "../../../icons/icons";
import { NewCategoryView } from "./new.category.view";
import { EditCategoryView } from "./edit.category.view";
import { RemoveCategoryView } from "./remove.category.view";
import { NewSubCategoryView } from "./new.sub.category.view";
import { ProductChangeView } from "./product.change.view";
import { EditProductView } from "./edit.product.view";
import { Category } from "../../../models/category";
import { Product } from "../../../models/product";
import { Option } from "../../../models/option";
import { getCookie } from "../../../helpers/cookies.helper";
import { StarFilled } from '@ant-design/icons';

interface IAllProductsState {
    categoriesList: Category[];
    selectedCategory: Category | undefined;
    removeCategory: Category;
    selectedSubCategory: Category | undefined;
    selectedProduct: Product;
    colorList: Option[];
    editCategory: Category;
    modalNewCategory: boolean;
    modalProductChange: boolean;
    modalNewSubCategory: boolean;
    modalEditCategory: boolean;
    modalEditProduct: boolean;
    modalRemoveCategory: boolean;
    newCategoryName: string;
}


export class AllProductsView extends React.Component<any, IAllProductsState>{
    constructor(props: any) {
        super(props);

        this.state = {
            categoriesList: [],
            selectedCategory: undefined,
            selectedProduct: new Product,
            colorList: [],
            modalNewCategory: false,
            modalProductChange: false,
            modalNewSubCategory: false,
            modalEditCategory: false,
            modalEditProduct: false,
            modalRemoveCategory: false,
            editCategory: new Category,
            removeCategory: new Category,
            selectedSubCategory: undefined,
            newCategoryName: ''
        }

        this.getAllCategories();
        this.getAllColors();
    }

    public getAllCategories() {
        fetch(API.API_GET_ALL_CATEGORIES, {
            method: "GET",
        }).then(response => response.json())
            .then(data => {
                  

                if (data.StatusCode === 200) {
                    this.setState({
                        categoriesList: data.Body
                    })
                      
                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_GET_ALL_CATEGORIES"));
    }

    public getAllColors() {
        let getAllColUrl = API.API_GET_ALL_OPTIONS_COLORS;
        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;

        fetch(getAllColUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AC,
            },
            method: "GET",
        }).then(response => response.json())
            .then(data => {
                if (data.StatusCode === 200) {
                     
                    this.setState({
                        colorList: data.Body.Options
                    })
                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_GET_ALL_COLORS"));
    }

    public onOpenCloseModalNewCategory() {
        this.setState({
            modalNewCategory: !this.state.modalNewCategory
        })
    }

    public onOpenCloseModalEditProduct() {
        this.setState({
            modalEditProduct: !this.state.modalEditProduct
        })
    }

    public onSelectedProduct(isSelectedProduct: Product) {
         
        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;
        fetch(API.API_GET_PRODUCT_BY_ID + isSelectedProduct.Id + '', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AC,
            },
            method: "GET",
        }).then(response => response.json())
            .then(data => {
                 
                if (data.StatusCode === 200) {
                    this.setState({
                        selectedProduct: data.Body,
                        modalProductChange: true
                    })
                     
                     
                } else {
                    console.log(data);
                }
            }).catch((e) => {
                 
                console.log("Error - API_GET_CATEGORY_BY_ID + isSelectedProduct")
            });

    }

    public onOpenCloseModalNewProduct(modalState) {
          
        if (modalState === 'open') {
            this.setState({
                modalProductChange: true,
                selectedProduct: new Product
            })
        } else if (modalState === 'close') {
            this.setState({
                modalProductChange: false
            })
        } else if (modalState === 'closeGetAll') {
            this.getAllCategories();

            this.setState({
                modalProductChange: false
            })
        }

    }

    public onOpenCloseModalNewSubCategory(category: Category) {
        this.setState({
            modalNewSubCategory: !this.state.modalNewSubCategory,
            selectedCategory: category
        })
    }

    public onOpenModalEditCategory(category: Category) {
        this.setState({
            editCategory: category,
            modalEditCategory: !this.state.modalEditCategory
        })
    }

    public onCloseModalEditCategory() {
        this.setState({
            modalEditCategory: !this.state.modalEditCategory
        })
    }

    public onOpenModalRemoveCategory(removeCategory: Category) {
        this.setState({
            removeCategory: removeCategory,
            modalRemoveCategory: !this.state.modalRemoveCategory
        })
    }

    public onCloseModalRemoveCategory() {
        this.setState({
            modalRemoveCategory: !this.state.modalRemoveCategory
        })
    }

    public onSelectSubCategoryCommand(selectedSubCategory: Category) {
        this.onChangeProductList(selectedSubCategory.Id);
          
    }

    public onValueChangeCategoryName(event: any) {
        this.setState({
            newCategoryName: event.target.value
        })
    }

    public onValueChangeEditSelectedProductName(event: any) {
        this.setState({
            selectedProduct: { ...this.state.selectedProduct, Name: event.target.value }
        })
    }

    public onValueChangeEditProductPrice(event: any) {
        this.setState({
            selectedProduct: { ...this.state.selectedProduct, Price: +event.target.value }
        })
    }

    public onValueChangeEditProductPrintPrice(event: any) {
        this.setState({
            selectedProduct: { ...this.state.selectedProduct, PricePrint: +event.target.value }
        })
    }

    public onChangeProductList(isSelectedSubCategoryID: number) {
        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;
        fetch(API.API_GET_CATEGORY_BY_ID + isSelectedSubCategoryID, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AC,
            },
            method: "GET",
        }).then(response => response.json())
            .then(data => {

                if (data.StatusCode === 200) {
                    this.setState({
                        selectedSubCategory: data.Body,
                    })
                      
                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_GET_CATEGORY_BY_ID"));

    }

    public onValueChangeEditCategoryName(event: any) {
        this.setState({
            editCategory: { ...this.state.editCategory, Name: event.target.value }
        })
    }

    public onCategoryChangeCommand(command) {
        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;
        let changeBodyCategory: any;

        switch (command) {
            case 'new': {
                changeBodyCategory = {
                    Name: this.state.newCategoryName
                }
                break;
            }

            case 'newsub': {
                changeBodyCategory = {
                    Name: this.state.newCategoryName,
                    ParentCategory: {
                        Id: this.state.selectedCategory ? this.state.selectedCategory.Id : null
                    }
                }
                break;
            }

            case 'edit': {
                changeBodyCategory = {
                    Name: this.state.editCategory.Name,
                    Id: this.state.editCategory.Id,
                    ParentCategory: this.state.editCategory.ParentCategory ? this.state.editCategory.ParentCategory : null
                }
                break;
            }

            case 'remove': {
                changeBodyCategory = {
                    Deleted: true,
                    Id: this.state.removeCategory.Id
                }
                break;
            }
        }
          
        fetch(API.API_POST_CHANGE_CATEGORY, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AC,
            },
            method: "POST",
            body: JSON.stringify(changeBodyCategory),
        }).then(response => response.json())
            .then(data => {
                  
                if (data.StatusCode === 200) {
                    this.setState({
                        categoriesList: data.Body,
                        newCategoryName: '',
                        modalEditCategory: false,
                        modalNewCategory: false,
                        modalNewSubCategory: false,
                        modalRemoveCategory: false,
                        selectedProduct: new Product,
                        selectedSubCategory: undefined,
                        modalProductChange: false
                    })
                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_POST_CHANGE_CATEGORY"));
    }

    public render() {
        return (
            <>
                <div className="products__PAGE">
                    {(this.state.selectedProduct && this.state.modalProductChange && this.state.selectedProduct.Colors) ?
                        <ProductChangeView
                            onChangeProductList={this.onChangeProductList.bind(this)}
                            selectedCategory={this.state.selectedCategory ? this.state.selectedCategory : new Category}
                            selectedSubCategory={this.state.selectedSubCategory ? this.state.selectedSubCategory : new Category}
                            colorList={this.state.colorList}
                            selectedProduct={this.state.selectedProduct}
                            onSelectedProduct={this.onSelectedProduct.bind(this)}
                            onValueChangeEditSelectedProductName={this.onValueChangeEditSelectedProductName.bind(this)}
                            onValueChangeEditProductPrice={this.onValueChangeEditProductPrice.bind(this)}
                            onValueChangeEditProductPrintPrice={this.onValueChangeEditProductPrintPrice.bind(this)}
                            onOpenCloseModalNewProduct={this.onOpenCloseModalNewProduct.bind(this)}

                        /> :

                        <div className="categories__CONTAINER card__DEFAULT2">
                            {this.state.modalEditProduct ?
                                <EditProductView
                                    onOpenCloseModalEditProduct={this.onOpenCloseModalEditProduct.bind(this)}
                                /> : null
                            }

                            <div className="page__TITLE">
                                <span>Категории</span>
                                <div className="add__CONTROL" onClick={() => this.onOpenCloseModalNewCategory()}>добавить</div>
                            </div>

                            {
                                this.state.categoriesList.map((category: any, index: number) =>
                                    <details className="category__ITEM" key={index} open>
                                        <summary>
                                            {category.Name}
                                            <div className="counter__CONTAINER">
                                                <small>кол-во</small>
                                            </div>
                                            <IconArrow />
                                        </summary>
                                        <div className="summary__CONTROLS">
                                            <ul>
                                                <li className="control__EDIT"
                                                    onClick={() => this.onOpenCloseModalNewSubCategory(category)}>
                                                    Добавить саб категорию
                                                </li>
                                                <li className="control__EDIT"
                                                    onClick={() => this.onOpenModalEditCategory(category)}>
                                                    Изменить
                                                </li>
                                                <li className="control__REMOVE"
                                                    onClick={() => this.onOpenModalRemoveCategory(category)}>
                                                    <IconRemove />
                                                </li>
                                            </ul>
                                        </div>

                                        <ul className="details__SubList__CONTAINER">
                                            {category.Categories ? category.Categories.map((subCategory: Category, index: number) =>
                                                <li className="list__ITEM" key={index} onClick={() => this.onSelectSubCategoryCommand(subCategory)}>
                                                    <label>
                                                        {subCategory.Name}
                                                    </label>
                                                    <div className="counter__CONTAINER">
                                                        {subCategory.Products.length}
                                                    </div>
                                                </li>
                                            ) : null}
                                        </ul>
                                    </details>
                                )
                            }
                        </div>
                    }

                    <div className="products__CONTAINER card__DEFAULT2">
                        <div className="page__TITLE">
                            <span>Продукты</span>
                            {this.state.selectedSubCategory ? <div className="add__CONTROL" onClick={() => this.onOpenCloseModalNewProduct('open')}>добавить</div> : null}
                        </div>

                        {this.state.selectedSubCategory ?
                            <div className="selectedSubCategory__CONTAINER card__DEFAULT">
                                <div className="categoryName__CONTAINER">
                                    <span>
                                        категория:
                                    </span>
                                    {this.state.selectedSubCategory.Name}
                                </div>

                                <div className="control__EDIT" onClick={() => this.onOpenModalEditCategory(this.state.selectedSubCategory ? this.state.selectedSubCategory : new Category)}>
                                    <IconEdit />
                                </div>

                                <div className="control__REMOVE" onClick={() => this.onOpenModalRemoveCategory(this.state.selectedSubCategory ? this.state.selectedSubCategory : new Category)}>
                                    <IconRemove />
                                </div>
                            </div> : null
                        }

                        <ul className="products__LIST_CONTAINER card__DEFAULT">
                            {
                                this.state.selectedSubCategory && this.state.selectedSubCategory.Products ? this.state.selectedSubCategory.Products.map((product: Product, index: number) =>
                                    <li className="productList__item" key={index} onClick={() => this.onSelectedProduct(product)}>
                                        <div className="pli__imgWrapper">
                                            <IconPhoto />
                                            {product.Image &&
                                                <div className="card__DEFAULT hide">
                                                    {product.Image && <img src={product.Image.ImageUrl} />}
                                                </div>
                                            }
                                        </div>
                                        <div className="pli__name">{product.Name ? product.Name : 'Error - selectedSubCategory[product.name]'}</div>
                                        {product.IsVisible === true && <div className="pli__vis"><StarFilled /></div>}
                                    </li>
                                ) : null
                            }
                        </ul>
                    </div>
                </div>

                {
                    this.state.modalNewCategory ?
                        <NewCategoryView
                            onOpenCloseModalNewCategory={this.onOpenCloseModalNewCategory.bind(this)}
                            newCategoryName={this.state.newCategoryName}
                            onCategoryChangeCommand={this.onCategoryChangeCommand.bind(this)}
                            onValueChangeCategoryName={this.onValueChangeCategoryName.bind(this)}
                        />
                        : null
                }

                {
                    this.state.modalNewSubCategory ?
                        <NewSubCategoryView
                            onOpenCloseModalNewSubCategory={this.onOpenCloseModalNewSubCategory.bind(this)}
                            newCategoryName={this.state.newCategoryName}
                            onCategoryChangeCommand={this.onCategoryChangeCommand.bind(this)}
                            onValueChangeCategoryName={this.onValueChangeCategoryName.bind(this)}
                        />
                        : null
                }

                {
                    this.state.modalEditCategory ?
                        <EditCategoryView
                            onCloseModalEditCategory={this.onCloseModalEditCategory.bind(this)}
                            editCategory={this.state.editCategory}
                            onCategoryChangeCommand={this.onCategoryChangeCommand.bind(this)}
                            onValueChangeEditCategoryName={this.onValueChangeEditCategoryName.bind(this)}
                        />
                        : null
                }

                {
                    this.state.modalRemoveCategory ?
                        <RemoveCategoryView
                            onCloseModalRemoveCategory={this.onCloseModalRemoveCategory.bind(this)}
                            removeCategory={this.state.removeCategory}
                            onCategoryChangeCommand={this.onCategoryChangeCommand.bind(this)}
                        />
                        : null
                }



            </>
        )
    }
}