import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import * as routes from "../common/routes";
import { Header } from "./component/header";
import { LeftMenu } from "./component/leftmenu";
import { AllProductsView } from "./dashboard/product/all.products.view";
import { DashboardView } from "./dashboard/dashboard.view";
import { ColorsView } from "./dashboard/options/colors";
import { AllOrdersView } from "./dashboard/order/all.orders.view";
import { PreviewOrderView } from "./dashboard/order/prev.order.view";
import { OptionType } from "./dashboard/options/option.type";
import { AllUsersView } from "./dashboard/user/all.users.view";
import { useSelector } from "react-redux";
import { IApplicationState } from "../reducers/application.state";
import { AuthenticationState } from "../reducers/authentication.slice";

const MasterView: React.FC = () => {

    const AuthenticationState = useSelector<IApplicationState, AuthenticationState>((state) => state.authentication);

    {
        if (AuthenticationState.role === 'Admin') {
            return (
                <div className="master__PAGE">
                    <Header />
                    <LeftMenu />
                    <div className="page__CONTAINER">
                        <div className="page__CONTENT">
                            <Switch>
                                <Route exact={true} path={routes.AllOrdersView} component={AllOrdersView} />
                                <Route exact={true} path={`${routes.AllOrdersView}/order/:id`} component={PreviewOrderView} />
                                <Route exact={true} path={routes.AllProductsView} component={AllProductsView} />
                                <Route exact={true} path={routes.DashboardView} component={DashboardView} />
                                <Route exact={true} path={routes.ColorsView} component={ColorsView} />
                                <Route exact={true} path={routes.OptionsView} component={OptionType} />
                                <Route exact={true} path={routes.AllUsersView} component={AllUsersView} />

                                <Redirect
                                    exact={true}
                                    from={'*'}
                                    to={`${routes.AllOrdersView}`} />
                            </Switch>
                        </div>
                    </div>
                </div>
            )
        } else if (AuthenticationState.role === 'Operator') {
            return (
                <div className="master__PAGE">
                    <Header />
                    <LeftMenu />
                    <div className="page__CONTAINER">
                        <div className="page__CONTENT">
                            <Switch>
                                <Route exact={true} path={routes.AllOrdersView} component={AllOrdersView} />
                                <Route exact={true} path={`${routes.AllOrdersView}/order/:id`} component={PreviewOrderView} />


                                <Redirect
                                    exact={true}
                                    from={'*'}
                                    to={`${routes.AllOrdersView}`} />
                            </Switch>
                        </div>
                    </div>
                </div>
            )
        } else if (AuthenticationState.role === 'Content') {
            return (
                <div className="master__PAGE">
                    <Header />
                    <LeftMenu />
                    <div className="page__CONTAINER">
                        <div className="page__CONTENT">
                            <Switch>
                                <Route exact={true} path={routes.AllProductsView} component={AllProductsView} />
                                <Route exact={true} path={routes.ColorsView} component={ColorsView} />
                                <Route exact={true} path={routes.OptionsView} component={OptionType} />

                                <Redirect
                                    exact={true}
                                    from={'*'}
                                    to={`${routes.AllProductsView}`} />
                            </Switch>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default MasterView;