import React from 'react';
import * as ReactDOM from 'react-dom';

import storeProvider from './helpers/store.provider';

import { createBrowserHistory } from "history";

import { Route, Switch, Router } from "react-router-dom";
import Routing from './routing';
import { Provider } from 'react-redux';

import configure from './store/store.config';
import { AppInit } from './helpers/app.init'

import './assets/scss/main.scss';
import 'antd/dist/antd.css';

//const store = InitializeStore(AppState);
const history = createBrowserHistory();
const initialState = (window as any).initialRedusState;
const store = configure(history, initialState);

storeProvider.init(store);

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Routing path='/' onEnter={AppInit(store)}/>
        </Router>
    </Provider>,
    document.getElementById('root')
);

//serviceWorker.unregister();
