import { Store } from 'redux'
import { ofType } from 'redux-observable'
import { authenticationActions } from '../reducers/authentication.slice'
import { ajax } from 'rxjs/ajax'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import API from '../constants/api.constants'
import { TokenHelper } from '../helpers/token.helper'
import { setCookie } from '../helpers/cookies.helper'
import storeProvider from '../helpers/store.provider'
import { push } from 'react-router-redux'
import { ErrorHandler } from '../helpers/error.handling';
import { IServiceApplicationState } from '../reducers/application.state'
import { userManagementActions } from '../reducers/user.management.slice'
import { of } from 'rxjs'
import { notification } from 'antd'

export const RequestToken = (action$, store: Store<any>) => {
     
    return action$.pipe(
        ofType(authenticationActions.api_requestToken.type),
        switchMap((action: any) => {
             

            return ajax
                .getJSON(`${API.SERVER_URL}${API.UserIdentityEndPoints.SIGN_IN}${action.payload.Email}&password=${action.payload.Password}`,
                    { 'Content-Type': 'application/json' }
                )
                .pipe(
                    map((response: any) => {
                         
                        const parsedToken = TokenHelper.parseJwt(response.Body.access_token)
                         
                        setCookie(
                            'token',
                            response.Body.access_token,
                            parsedToken.exp * 1000
                        )
                        //setCookie(
                        //    'refresh',
                        //    response.Body.RefreshToken,
                        //    Math.round(+new Date() / 1000) + 43200 * 60
                        //)

                        //storeProvider.getStore().dispatch(push('/'))
                        window.location.href = '/';

                        return authenticationActions.requestTokenSuccess({
                            name: parsedToken.unique_name,
                            email: parsedToken.email,
                            netUid: parsedToken.ID,
                            role: parsedToken.role,
                            token: response.Body.access_token,
                            expires: parsedToken.exp * 1000
                        })


                    }),
                    catchError((error: any) => {
                        return ErrorHandler(error, true)
                    })
                )
        }
        )
    )
}

export const GetRoles = (action$, state$: IServiceApplicationState) =>
    action$.pipe(
        ofType(userManagementActions.apiGetRoles.type),
        switchMap((action: any) => {
             
            return ajax
                .getJSON(
                    `${API.SERVER_URL}${API.UserIdentityEndPoints.GET_ALL_ROLES}`,
                    {
                        Authorization: `Bearer ${state$.value.authentication.token}`,
                        'Content-Type': 'application/json',
                    }
                )
                .pipe(
                    mergeMap((response: any) => {
                         
                        return of(
                            userManagementActions.setRoles(response.Body),
                        )
                    }),
                    catchError((error: any) => {
                        return ErrorHandler(error)
                    })
                )
        })
    )

export const GetUsers = (action$, state$: IServiceApplicationState) =>
    action$.pipe(
        ofType(userManagementActions.apiGetUsers.type),
        switchMap((action: any) => {
             
            return ajax
                .getJSON(
                    `${API.SERVER_URL}${API.UserIdentityEndPoints.GET_ALL_USERS}`,
                    {
                        Authorization: `Bearer ${state$.value.authentication.token}`,
                        'Content-Type': 'application/json',
                    }
                )
                .pipe(
                    mergeMap((response: any) => {
                         
                        
                        if (response.Body.length > 0)
                            return of(
                                userManagementActions.setUsersList(response.Body),
                            )
                        else
                            return of(
                                userManagementActions.setUsersList(response.Body),
                            )
                    }),
                    catchError((error: any) => {
                        return ErrorHandler(error)
                    })
                )
        })
    )

export const apiCreateUserEpic = (action$, state$: IServiceApplicationState) => {
    return action$.pipe(
        ofType(userManagementActions.apiNewUser.type),
        switchMap((action: any) => {
             
            return ajax
                .post(`${API.SERVER_URL}${API.UserIdentityEndPoints.NEW_USER_PROFILE}`,
                    action.payload,
                    {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${state$.value.authentication.token}`
                    }
                )
                .pipe(
                    mergeMap((response: any) => {
                         
                        notification.success({
                            description: '',
                            message: "Пользователь создан",
                            className: 'notification_item',
                        })
                        return of(
                            push('/app/users'),
                            userManagementActions.setUsersList(response.response.Body)
                        )
                    }),
                    catchError((error: any) => {
                        return ErrorHandler(error)
                    })
                )
        }
        )
    )
}

export const apiUpdateUserEpic = (action$, state$: IServiceApplicationState) => {
    return action$.pipe(
        ofType(userManagementActions.apiUpdateUser.type),
        switchMap((action: any) => {
             
            return ajax
                .post(`${API.SERVER_URL}${API.UserIdentityEndPoints.UPDATE_USER_PROFILE}`,
                    action.payload,
                    {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${state$.value.authentication.token}`
                    }
                )
                .pipe(
                    mergeMap((response: any) => {
                         
                        notification.success({
                            description: '',
                            message: 'Изменен',
                            className: 'notification_item',
                        })
                        return of(
                            push('/app/users'),
                            userManagementActions.setUsersList(response.response.Body)
                        )
                    }),
                    catchError((error: any) => {
                        return ErrorHandler(error)
                    })
                )
        }
        )
    )
}

export const apiChangeUserPasswordEpic = (action$, state$: IServiceApplicationState) => {
    return action$.pipe(
        ofType(userManagementActions.apiChangeUserPassword.type),
        switchMap((action: any) => {
             
            return ajax
                .post(`${API.SERVER_URL}${API.UserIdentityEndPoints.CHANGE_PASSWORD}`,
                    action.payload,
                    {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${state$.value.authentication.token}`
                    }

                )
                .pipe(
                    mergeMap((response: any) => {
                         
                        notification.success({
                            description: '',
                            message: "Пароль изменен",
                            className: 'notification_item',
                        })
                        return of(
                            push('/app/users'),
                            userManagementActions.setUsersList(response.response.Body)
                        )
                    }),
                    catchError((error: any) => {
                        return ErrorHandler(error)
                    })
                )
        }
        )
    )
}

export const apiRemoveUser = (action$, state$: IServiceApplicationState) => {
    return action$.pipe(
        ofType(userManagementActions.apiRemoveUser.type),
        switchMap((action: any) => {
             
            return ajax
                .post(`${API.SERVER_URL}${API.UserIdentityEndPoints.REMOVE_USER}${action.payload}`,
                    {},
                    {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${state$.value.authentication.token}`
                    }

                )
                .pipe(
                    mergeMap((response: any) => {
                         
                        notification.success({
                            description: '',
                            message: "Пользователь удален",
                            className: 'notification_item',
                        })
                        return of(
                            push('/app/users'),
                            userManagementActions.setUsersList(response.response.Body)
                        )
                    }),
                    catchError((error: any) => {
                        return ErrorHandler(error)
                    })
                )
        }
        )
    )
}