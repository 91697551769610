import { Button, Popconfirm } from "antd";
import { Field, Form, Formik, FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { EnumModel } from "../../../entities/user/enum.model";
import { UserProfile } from "../../../entities/user/user.profile";
import { IconFolder, IconSettings } from "../../../icons/icons";
import { IApplicationState } from "../../../reducers/application.state";
import { userManagementActions } from "../../../reducers/user.management.slice";
import * as Yup from 'yup';
import { FormicReference } from "../../auth/security.interfaces";
import { authenticationActions } from "../../../reducers/authentication.slice";

export const AllUsersView: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const users = useSelector<IApplicationState, UserProfile[]>(state => state.userManagement.usersList)
    const userRoles = useSelector<IApplicationState, string[]>(state => state.userManagement.userRoles)
    const selectedUser = useSelector<IApplicationState, UserProfile>(state => state.userManagement.selectedUserProfile)

    useEffect(() => {
        if (users.length === 0) {
            dispatch(userManagementActions.apiGetUsers())
        }
    }, [users])

    useEffect(() => {
        if (users.length === 0) {
            dispatch(userManagementActions.apiGetRoles())
        }
    }, [])

    const [formikReferencePass] = useState<FormicReference>(
        new FormicReference(() => {

        })
    )

    const [formikReferenceUs] = useState<FormicReference>(
        new FormicReference(() => {

        })
    )

    const UserSchema = Yup.object().shape({
        name: Yup.string()
            .min(4, () => 'Name must contain at least 4 characters')
            .required(() => 'Name Required'),
        email: Yup.string()
            .email(() => 'Email incorrect')
            .required(() => 'Email Required'),
        password: Yup.string()
            .min(8, () => 'Password must contain at least 8 characters')
            .required(() => 'Password Required'),
    })

    const EditUserSchema = Yup.object().shape({
        name: Yup.string()
            .min(4, () => 'Name must contain at least 4 characters')
            .required(() => 'Name Required'),
        email: Yup.string()
            .email(() => 'Email incorrect')
            .required(() => 'Email Required')
    })

    const UserPassSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, () => 'Password must contain at least 8 characters')
            .required(() => 'Password Required'),
    })

    return (
        <>
            <div className="options__PAGE">
                <div className="colorList__CONTAINER">
                    <div className="page__TITLE">
                        <span>Пользователи</span>
                    </div>

                    <div className="newColor__SECTION">
                        <ul className="t_LINE">
                            <li className="color__ROW_INFO">
                                <div className="l__ICON">
                                    <IconFolder />
                                </div>
                                <div className="categories__INFO_P">
                                    <p className="category__NAME_T"> </p>
                                    <p className="category__NAME_B">Список пользователей</p>
                                </div>

                                <div className="product__NAME_P">
                                    <div className="product__NAME_ROW" style={{ height: 'auto' }}>
                                        <ul>
                                            {users.map((user: UserProfile, index: number) =>
                                                <li key={index}
                                                    className="user__ITEM"
                                                    style={{ minHeight: '20px' }}
                                                    onClick={() => dispatch(userManagementActions.setUserProfile(user))}>
                                                    <div className="co_I email">{user.Email}</div>
                                                    <div className="co_I name">{user.Name}</div>
                                                    <div className="co_I role">{user.Role}</div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>

                            </li>
                        </ul>

                    </div>
                </div>

                <div className="selectedColor__CONTAINER_R">
                    <div className="page__TITLE">
                        {selectedUser.Id === '' ? <span>Создание</span> : <span>Редактирование</span>}
                    </div>

                    <div className="editingColor__CONTAINER card__DEFAULT userEdit__VW"
                        style={{
                            paddingTop: '10px',
                            maxHeight: '700px'
                        }}>

                        <Formik
                            enableReinitialize
                            initialValues={{
                                email: selectedUser.Email,
                                password: '',
                                name: selectedUser.Name,
                                role: selectedUser.Role
                            }}
                            innerRef={(formik: any) => {
                                formikReferenceUs.formik = formik
                            }}
                            validationSchema={selectedUser.Id === '' ? UserSchema : EditUserSchema}
                            onSubmit={(values) => {

                                dispatch(userManagementActions.setUserProfile(new UserProfile()))

                                {
                                    if (selectedUser.Id === '') {
                                        const data: UserProfile = {
                                            Email: values.email,
                                            Password: values.password,
                                            Name: values.name,
                                            Role: values.role
                                        }

                                        dispatch(userManagementActions.apiNewUser(data))
                                    } else {
                                        const data: UserProfile = {
                                            Id: selectedUser.Id,
                                            Email: values.email,
                                            Name: values.name,
                                            Role: values.role
                                        }
                                        dispatch(userManagementActions.apiUpdateUser(data))
                                    }
                                }
                            }}>
                            {(formik) => {
                                return (
                                    <Form className="">
                                        <div className="input__CONTROL">
                                            <p className="product__NAME_T">Имя</p>
                                        </div>
                                        <Field
                                            name="name"
                                            type="name"
                                            spellCheck="false"
                                            className="ant-input ant-input-lg"
                                            autoComplete="off"
                                            value={formik.values.name}
                                        />
                                        {formik.errors.name && formik.touched.name ? (
                                            <span className="form_valMessage">
                                                {formik.errors.name}
                                            </span>
                                        ) : null}

                                        <div className="input__CONTROL">
                                            <p className="product__NAME_T">Роль</p>
                                        </div>
                                        <Field
                                            name="role"
                                            type="role"
                                            as="select"
                                            spellCheck="false"
                                            className="ant-input ant-input-lg"
                                            autoComplete="off"
                                        >
                                            {userRoles.map((string: any, index: number) =>
                                                <option key={index} value={string}>{string}</option>
                                            )}
                                        </Field>

                                        <div className="input__CONTROL">
                                            <p className="product__NAME_T">E-mail</p>
                                        </div>
                                        <Field
                                            name="email"
                                            type="email"
                                            spellCheck="false"
                                            className="ant-input ant-input-lg"
                                            autoComplete="off"
                                        />

                                        {formik.errors.email && formik.touched.email ? (
                                            <span className="form_valMessage">
                                                {formik.errors.email}
                                            </span>
                                        ) : null}

                                        {
                                            selectedUser.Id === '' ?
                                                <>
                                                    <div className="input__CONTROL">
                                                        <p className="product__NAME_T">Пароль</p>
                                                    </div>
                                                    <Field
                                                        name="password"
                                                        type="password"
                                                        spellCheck="false"
                                                        className="ant-input ant-input-lg"
                                                        autoComplete="new-password"
                                                    />

                                                    {formik.errors.password && formik.touched.password ? (
                                                        <span className="form_valMessage">
                                                            {formik.errors.password}
                                                        </span>
                                                    ) : null}
                                                </> :
                                                <></>
                                        }



                                        <div className="input__CONTROL">
                                            <div className="editing__CONTROLS">
                                                <Button size={"middle"} type="primary" htmlType="submit" style={{ float: 'right' }}>
                                                    {selectedUser.Id === '' ? <span>Создать</span> : <span>Изменить</span>}
                                                </Button>

                                                <Button size={"middle"} type="default" style={{ float: 'right', marginRight: '10px' }} onClick={() => {
                                                    formik.resetForm(
                                                        {
                                                            values: {
                                                                email: '',
                                                                name: '',
                                                                password: '',
                                                                role: 'Admin'
                                                            }
                                                        })
                                                    dispatch(userManagementActions.setUserProfile(new UserProfile()))
                                                }}>Отмена</Button>
                                            </div>
                                        </div>
                                    </Form>

                                )
                            }
                            }
                        </Formik>
                        
                        <Formik
                            initialValues={{
                                password: ''
                            }}
                            innerRef={(formik: any) => {
                                formikReferencePass.formik = formik
                            }}
                            validationSchema={UserPassSchema}
                            onSubmit={(values) => {

                                const data: any = {
                                    Id: selectedUser.Id,
                                    NewPassword: values.password
                                }

                                dispatch(userManagementActions.setUserProfile(new UserProfile()))
                                dispatch(userManagementActions.apiChangeUserPassword(data))
                            }}
                        >
                            {(formik) => {
                                return (
                                    <Form className="">
                                        {
                                            selectedUser.Id !== '' &&
                                            <>
                                                <div className="input__CONTROL">
                                                    <p className="product__NAME_T">Пароль</p>
                                                </div>
                                                <Field
                                                    name="password"
                                                    type="password"
                                                    spellCheck="false"
                                                    className="ant-input ant-input-lg"
                                                    autoComplete="edit-password"
                                                />

                                                {formik.errors.password && formik.touched.password ? (
                                                    <span className="form_valMessage">
                                                        {formik.errors.password}
                                                    </span>
                                                ) : null}
                                                <div className="input__CONTROL">
                                                    <div className="editing__CONTROLS">
                                                        <Button size={"middle"} type="primary" htmlType="submit" style={{ float: 'right' }}>
                                                            <span>Изменить</span>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </Form>
                                )
                            }
                            }
                        </Formik>

                        <div className="input__CONTROL">
                            <div className="editing__CONTROLS">
                                {
                                    selectedUser.Id !== '' &&
                                    <Popconfirm placement="topLeft" title={'Удалить ?'} onConfirm={(event) => {
                                        event.preventDefault()
                                        event.stopPropagation()
                                        dispatch(userManagementActions.apiRemoveUser(selectedUser.Id))
                                    }} okText="Да" cancelText="Нет">
                                        <Button size={"middle"} type="default" style={{ float: 'left', marginRight: '10px' }}>Удалить пользователя</Button>
                                    </Popconfirm>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
