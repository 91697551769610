import { ofType } from "redux-observable";
import { orderActions } from "../reducers/order.slice";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { switchMap, mergeMap, catchError } from "rxjs/operators";
import { IServiceApplicationState } from "../reducers/application.state";

import * as API from "../constants/api.constants";
import { notification } from "antd";
import { push } from 'react-router-redux'

export const GetOrderAll = (action$, state$: IServiceApplicationState) => action$.pipe(
    ofType(orderActions.apiGetOrdersAll.type),
    switchMap((action: any) => {
         
        return ajax
            .getJSON(
                `${API.API_GET_ALL_ORDERS}`,
                {
                    Authorization: `Bearer ${localStorage.getItem('AC')}`,
                    'Content-Type': 'application/json',
                }
            )
            .pipe(
                mergeMap((response: any) => {
                     
                    return of(
                        orderActions.setOrderList(response.Body)
                    )
                }),
                //catchError((error: any) => {
                //    return ErrorHandler(error)
                //})
            )
    })
)

export const GetOrderById = (action$, state$: IServiceApplicationState) => action$.pipe(
    ofType(orderActions.apiGetOrderById.type),
    switchMap((action: any) => {
         
        return ajax
            .getJSON(
                `${API.API_GET_ORDER}${action.payload}`,
                {
                    Authorization: `Bearer ${localStorage.getItem('AC')}`,
                    'Content-Type': 'application/json',
                }
            )
            .pipe(
                mergeMap((response: any) => {
                     
                    return of(
                        orderActions.setOrder(response.Body)
                    )
                }),
                //catchError((error: any) => {
                //    return ErrorHandler(error)
                //})
            )
    })
)

export const GetZip = (action$, state$: IServiceApplicationState) => action$.pipe(
    ofType(orderActions.apiGetOrderZip.type),
    switchMap((action: any) => {
         
        return ajax
            .getJSON(
                `${API.API_GET_ORDER_ZIP}${action.payload}`,
                {
                    Authorization: `Bearer ${localStorage.getItem('AC')}`,
                    'Content-Type': 'application/json',
                }
            )
            .pipe(
                mergeMap((response: any) => {
                    notification.success({
                        description: '',
                        message: 'Архив создан',
                        className: 'notification_item',

                    })
                    return of(
                        orderActions.setOrderZip(response.Body)
                    )
                }),
                //catchError((error: any) => {
                //    return ErrorHandler(error)
                //})
            )
    })
)

export const apiChangeOrderUser = (action$, state$: IServiceApplicationState) => {
    return action$.pipe(
        ofType(orderActions.apiOrderUserId.type),
        switchMap((action: any) => {
             
            return ajax
                .post(`${API.API_CHANGE_USER_FOR_ORDER}${action.payload}`,
                    {},
                    {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${state$.value.authentication.token}`
                    }
                )
                .pipe(
                    mergeMap((response: any) => {
                         
                        notification.success({
                            description: '',
                            message: "Заказ принят",
                            className: 'notification_item',
                        })
                        return of(
                            orderActions.setOrder(response.response.Body)
                        )
                    }),
                    //catchError((error: any) => {
                    //    return ErrorHandler(error)
                    //})
                )
        }
        )
    )
}

export const apiChangeOrderStatus = (action$, state$: IServiceApplicationState) => {
    return action$.pipe(
        ofType(orderActions.apiChangeOrderStatus.type),
        switchMap((action: any) => {
             
            return ajax
                .post(`${API.API_CHANGE_ORDER_STATUS}${action.payload.id}&status=${action.payload.status}`,
                    {},
                    {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${state$.value.authentication.token}`
                    }
                )
                .pipe(
                    mergeMap((response: any) => {
                         
                        //notification.success({
                        //    description: '',
                        //    message: "Заказ принят",
                        //    className: 'notification_item',
                        //})
                        return of(
                           orderActions.setOrder(response.response.Body)
                        )
                    }),
                    //catchError((error: any) => {
                    //    return ErrorHandler(error)
                    //})
                )
        }
        )
    )
}