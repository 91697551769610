import React from "react";
import * as API from "../../../constants/api.constants";
import { IconClose } from "../../../icons/icons";

export class NewCategoryView extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div className="newControl__MODAL">
                <div className="modal__FRAME">
                    <div className="modal__CLOSE" onClick={() => this.props.onOpenCloseModalNewCategory()}>
                        <IconClose/>
                    </div>
                    <div className="modal__HEADER">
                        Новая категория
                    </div>
                    <br />
                    <div className="modal__BODY">
                        <input type="text"
                            placeholder="Название"
                            value={this.props.newCategoryName}
                            onChange={(event: any) => this.props.onValueChangeCategoryName(event)}
                        />
                        <div className="new__CONTROL" onClick={(event: any) => this.props.onCategoryChangeCommand("new")}>Сохранить</div>
                    </div>
                </div>
            </div>
        )
    }
}