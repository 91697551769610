import React from "react";
import { IconClose, IconFolder, IconSettings, IconEdit, IconRemove, IconBack, IconPhoto, IconPriceRatio } from "../../../icons/icons";
import * as API from "../../../constants/api.constants";
import { Category } from "../../../models/category";
import { Product } from "../../../models/product";
import { Option } from "../../../models/option";
import { ProductOption } from "../../../models/productOption";
import { PriceFrame } from "../../../models/price.frame";
import { TypeOption } from "../../../models/type.option";
import { getCookie } from "../../../helpers/cookies.helper";
import { Button, Modal, notification, Tooltip } from "antd";
import { EditOutlined } from '@ant-design/icons';
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";


interface IProductChangeState {
    productName: string;
    productChangeName: string;
    productChangePrice: number;
    productChangePrintPrice: number;
    productChangeNewPrice: number | undefined,
    productChangeNewPrintPrice: number | undefined,
    optionPriceRatio: number | undefined;
    selectedCategory: Category;
    removeCategory: Category;
    selectedProduct: Product;
    selectedColor: Option;
    selectedColorImgUrl: FormData;
    selectedOptionImgUrl: FormData;
    selectedProductImgUrl: FormData;
    selectedPriceFrame: PriceFrame;
    isSelectedColor: boolean;
    rowPriceFrom: string;
    rowPriceTo: string;
    rowPriceCof: string;
    rowPricePrintCof: string;
    removePriceFrame: PriceFrame;
    isSelectedPriceFrameModal: boolean;
    isSelectedRemoveProductModal: boolean;
    enumTypePriceAction: number;
    optionTypeList: any;
    selectedOption: Option;
    isSelectedOption: boolean;
    selectedPriceLvl: PriceFrame;
}

interface IProductChangeProps {
    selectedProduct: Product;
    selectedCategory: Category;
    selectedSubCategory: Category;
    colorList: Option[];
    onSelectedProduct(value: any): void;
    onChangeProductList(value: any): void;
    onValueChangeEditSelectedProductName(value: any): void;
    onOpenCloseModalNewProduct(value: any): void;
    onValueChangeEditProductPrintPrice(value: any): void;
    onValueChangeEditProductPrice(value: any): void;
    //onSelectedProduct(value: any): void;
}

export class ProductChangeView extends React.Component<IProductChangeProps, IProductChangeState>{

    constructor(props: any) {
        super(props);

        this.state = {
            productName: '',
            productChangeName: this.props.selectedProduct.Name,
            optionPriceRatio: undefined,
            productChangePrice: this.props.selectedProduct.Price,
            productChangePrintPrice: this.props.selectedProduct.PricePrint,
            productChangeNewPrice: undefined,
            productChangeNewPrintPrice: undefined,
            selectedCategory: this.props.selectedCategory,
            selectedProduct: this.props.selectedProduct,
            selectedColor: new Option,
            selectedColorImgUrl: new FormData,
            selectedOptionImgUrl: new FormData,
            selectedProductImgUrl: new FormData,
            isSelectedColor: false,
            removeCategory: new Category,
            rowPriceFrom: '',
            rowPriceTo: '',
            rowPriceCof: '',
            rowPricePrintCof: '',
            removePriceFrame: new PriceFrame,
            isSelectedPriceFrameModal: false,
            selectedPriceFrame: new PriceFrame,
            enumTypePriceAction: 1,
            isSelectedRemoveProductModal: false,
            optionTypeList: [],
            selectedOption: new Option,
            isSelectedOption: false,
            selectedPriceLvl: new PriceFrame
        }
        this.getAllOptions()
    }

    public getAllOptions() {
        let getAllCotUrl = API.API_GET_ALL_OPTIONS_TYPE;
        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;

        fetch(getAllCotUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AC,
            },
            method: "GET",

        }).then(response => response.json())
            .then(data => {
                if (data.StatusCode === 200) {

                    this.setState({
                        optionTypeList: data.Body
                    })
                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_GET_ALL_OPTIONS_TYPE"));
    }

    public onProductChangeCommand(command) {

        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;
        let changeBodyProduct: any;

        switch (command) {
            case 'new': {
                changeBodyProduct = {
                    Name: this.state.productName,
                    Category: this.props.selectedSubCategory,
                    PricePrint: +this.state.productChangeNewPrintPrice,
                    Price: +this.state.productChangeNewPrice
                }
                break;
            }

            case 'edit': {
                changeBodyProduct = {
                    Name: this.props.selectedProduct.Name,
                    Id: this.props.selectedProduct.Id,
                    Category: this.props.selectedSubCategory,
                    ImageId: this.props.selectedProduct.ImageId,
                    PricePrint: this.props.selectedProduct.PricePrint,
                    Price: this.props.selectedProduct.Price
                }
                break;
            }

            case 'remove': {
                changeBodyProduct = {
                    Deleted: true,
                    Id: this.state.selectedProduct.Id
                }
                break;
            }
        }

        fetch(API.API_POST_CHANGE_PRODUCT, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AC,
            },
            method: "POST",
            body: JSON.stringify(changeBodyProduct),
        }).then(response => response.json())
            .then(data => {

                if (data.StatusCode === 200) {
                    this.setState({
                        productName: data.Body.Name,
                        productChangeNewPrice: 1,
                        productChangeNewPrintPrice: 1,
                    })

                    this.props.onSelectedProduct(data.Body);
                    this.props.onChangeProductList(this.props.selectedSubCategory.Id);
                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_POST_CHANGE_CATEGORY"));
    }

    public onProductRemoveCommand(command) {
        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;
        let changeBodyProduct: any;

        switch (command) {
            case 'remove': {
                changeBodyProduct = {
                    Deleted: true,
                    Id: this.props.selectedProduct.Id
                }
                break;
            }
        }



        fetch(API.API_POST_CHANGE_PRODUCT, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AC,
            },
            method: "POST",
            body: JSON.stringify(changeBodyProduct),
        }).then(response => response.json())
            .then(data => {

                if (data.StatusCode === 200) {

                    this.setState({
                        isSelectedRemoveProductModal: false
                    })

                    // this.props.onSelectedProduct(new Product);
                    this.props.onChangeProductList(this.props.selectedSubCategory.Id);
                    this.props.onOpenCloseModalNewProduct('close');
                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_POST_CHANGE_CATEGORY"));
    }

    public manageColorToProduct(command) {

        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;
        let changeBodyColor: any;
        let formDataColor = this.state.selectedColorImgUrl;
        let formDataOption = this.state.selectedOptionImgUrl;

        switch (command) {
            case 'new': {

                let changeBodyColor = {
                    "Product": {
                        "Id": this.props.selectedProduct.Id
                    },
                    "Option": {
                        "Id": this.state.selectedColor.Id,
                    },
                    "TypePriceAction": this.state.enumTypePriceAction,
                    "PriceCoefficient": this.state.optionPriceRatio
                }

                formDataColor.append('productOptionInString', JSON.stringify(changeBodyColor))
                break;
            }

            case 'newOption': {

                let changeBodyColor = {
                    "Product": {
                        "Id": this.props.selectedProduct.Id
                    },
                    "Option": {
                        "Id": this.state.selectedOption.Id,
                    },
                    "TypePriceAction": this.state.enumTypePriceAction,
                    "PriceCoefficient": this.state.optionPriceRatio
                }

                formDataOption.append('productOptionInString', JSON.stringify(changeBodyColor))
                break;
            }
        }

        fetch(API.API_POST_CHANGECOLOR_TO_PRODUCT, {
            headers: {
                'Authorization': AC,
            },
            method: "POST",
            body: command === 'new' ? formDataColor : formDataOption,

        }).then(response => response.json())
            .then(data => {
                if (data.StatusCode === 200) {
                    //formData.delete('files');

                    this.setState({
                        selectedColorImgUrl: new FormData,
                        productName: data.Body.Name,
                        isSelectedColor: false,
                        isSelectedOption: false
                    })
                    this.props.onSelectedProduct(data.Body);
                    this.props.onChangeProductList(this.props.selectedSubCategory.Id);
                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_POST_CHANGECOLOR_TO_PRODUCT"));
    }

    public manageImageToProduct(command) {
        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;
        let changeBodyColor: any;

        fetch(API.API_POST_CHANGE_IMG_TO_PRODUCT + this.props.selectedProduct.Id, {
            headers: {
                'Authorization': AC,
            },
            method: "POST",
            body: this.state.selectedProductImgUrl,

        }).then(response => response.json())
            .then(data => {

                if (data.StatusCode === 200) {
                    this.setState({
                        selectedProductImgUrl: new FormData,
                        selectedProduct: data.Body
                    })
                    this.props.onSelectedProduct(data.Body);
                    // this.props.onChangeProductList(this.props.selectedSubCategory.Id);
                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_POST_CHANGECOLOR_TO_PRODUCT"));
    }

    public removeColorInProduct(option: ProductOption) {
        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;
        let formData = new FormData();

        let changeBodyOption = {
            "Id": option.Id,
            Deleted: true,
            Product: {
                Id: option.ProductId
            }
        }

        formData.append('productOptionInString', JSON.stringify(changeBodyOption))

        fetch(API.API_POST_CHANGECOLOR_TO_PRODUCT, {
            headers: {
                'Authorization': AC,
            },
            method: "POST",
            body: formData,
        }).then(response => response.json())
            .then(data => {

                if (data.StatusCode === 200) {
                    this.props.onSelectedProduct(data.Body);
                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_POST_CHANGE_OPTIONS"));
    }

    public onSelectedColor(isSelectedColor) {
        this.setState({
            selectedColorImgUrl: new FormData(),
            selectedColor: isSelectedColor,
            isSelectedColor: true
        })
    }

    public onSelectedOption(isSelectedOption) {
        this.setState({
            selectedOptionImgUrl: new FormData(),
            selectedOption: isSelectedOption,
            isSelectedOption: true
        })
    }

    public onValueChangeEditProductName(event: any) {
        this.setState({
            productName: event.target.value
        })
    }

    public onValueChangeEditProductNewPrice(event: any) {
        this.setState({
            productChangeNewPrice: event.target.value
        })
    }

    public onValueChangeEditProductNewPrintPrice(event: any) {
        this.setState({
            productChangeNewPrintPrice: event.target.value
        })
    }

    public onValueChangeProductImgUrl(event: any) {
        let formData = new FormData();
        formData.append('imageFile', event.target.files[0])

        this.setState({
            selectedProductImgUrl: formData
        })
    }

    public onValueChangeColorUrl(event: any) {
        let formData = new FormData();
        formData.append('files', event.target.files[0])

        this.setState({
            selectedColorImgUrl: formData
        })
    }

    public onValueChangeOptionUrl(event: any) {
        let formData = new FormData();
        formData.append('files', event.target.files[0])

        this.setState({
            selectedOptionImgUrl: formData
        })
    }

    public onValueChangeOptionPriceRatio(event: any) {
        this.setState({
            optionPriceRatio: event.target.value
        })
    }

    public onCloseModalProductChange() {
        this.setState({
            isSelectedColor: false,
            isSelectedOption: false
        })
    }

    public onCreateRowPriceLvl(command) {
        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;
        let changeBodyLvlPrice: any;

        switch (command) {
            case 'new': {
                changeBodyLvlPrice = {
                    Id: this.props.selectedProduct.Id,
                    Category: this.props.selectedSubCategory,
                    Name: this.props.selectedProduct.Name,
                    ImageId: this.props.selectedProduct.ImageId,
                    Price: this.props.selectedProduct.Price,
                    PricePrint: this.props.selectedProduct.PricePrint,
                    "PriceFrames": [
                        {
                            From: +this.state.rowPriceFrom,
                            To: +this.state.rowPriceTo,
                            PriceCoefficient: +this.state.rowPriceCof,
                            PricePrintCoefficient: +this.state.rowPricePrintCof
                        }
                    ]
                }
                break;
            }

            case 'Edit': {
                changeBodyLvlPrice = {
                    Id: this.props.selectedProduct.Id,
                    Category: this.props.selectedSubCategory,
                    Name: this.props.selectedProduct.Name,
                    ImageId: this.props.selectedProduct.ImageId,
                    Price: this.props.selectedProduct.Price,
                    PricePrint: this.props.selectedProduct.PricePrint,
                    "PriceFrames": [
                        {
                            From: +this.state.selectedPriceLvl.From,
                            To: +this.state.selectedPriceLvl.To,
                            PriceCoefficient: +this.state.selectedPriceLvl.PriceCoefficient,
                            PricePrintCoefficient: +this.state.selectedPriceLvl.PricePrintCoefficient
                        }
                    ]
                }
                break;
            }

            case 'remove': {
                changeBodyLvlPrice = {
                    Id: this.props.selectedProduct.Id,
                    Category: this.props.selectedSubCategory,
                    Name: this.props.selectedProduct.Name,
                    ImageId: this.props.selectedProduct.ImageId,
                    Price: this.props.selectedProduct.Price,
                    PricePrint: this.props.selectedProduct.PricePrint,
                    "PriceFrames": [
                        {
                            Deleted: true,
                            Id: this.state.selectedPriceFrame.Id
                        }
                    ]
                }
                break;
            }
        }

        fetch(API.API_POST_CHANGE_PRODUCT, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AC,
            },
            method: "POST",
            body: JSON.stringify(changeBodyLvlPrice),
        }).then(response => response.json())
            .then(data => {

                if (data.StatusCode === 200) {
                    this.props.onSelectedProduct(data.Body);
                    this.setState({
                        isSelectedPriceFrameModal: false,
                        rowPriceFrom: '',
                        rowPriceTo: '',
                        rowPriceCof: '',
                        rowPricePrintCof: ''
                    })
                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_POST_CHANGE_CATEGORY"));
    }

    public onEditRowPriceLvl(PriceFrames) {
        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;
         
        let changeBodyLvlPrice = {
            Id: this.props.selectedProduct.Id,
            Category: this.props.selectedSubCategory,
            Name: this.props.selectedProduct.Name,
            ImageId: this.props.selectedProduct.ImageId,
            Price: this.props.selectedProduct.Price,
            PricePrint: this.props.selectedProduct.PricePrint,
            "PriceFrames": [
                PriceFrames
            ]
        }
         

        fetch(API.API_POST_CHANGE_PRODUCT, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AC,
            },
            method: "POST",
            body: JSON.stringify(changeBodyLvlPrice),
        }).then(response => response.json())
            .then(data => {
                 
                if (data.StatusCode === 200) {
                    this.props.onSelectedProduct(data.Body);
                    this.setState({
                        selectedPriceLvl: new PriceFrame()
                    })
                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_POST_CHANGE_CATEGORY"));
    }

    public onValueChangeRowPriceFrom(event: any) {
        this.setState({
            rowPriceFrom: event.target.value
        })
    }

    public onValueChangeRowPriceTo(event: any) {
        this.setState({
            rowPriceTo: event.target.value
        })
    }

    public onValueChangeRowPriceCof(event: any) {
        this.setState({
            rowPriceCof: event.target.value
        })
    }

    public onValueChangeRowPricePrintCof(event: any) {
        this.setState({
            rowPricePrintCof: event.target.value
        })
    }

    public onSelectedPriceFrameItem(PriceFrameItem: PriceFrame) {
        this.setState({
            selectedPriceFrame: PriceFrameItem,
            isSelectedPriceFrameModal: true
        })
    }

    public onOpenCloseModalRemoveProductFrame() {
        this.setState({
            isSelectedRemoveProductModal: !this.state.isSelectedRemoveProductModal
        })
    }

    public onCloseModalRemovePriceFrame() {
        this.setState({
            isSelectedPriceFrameModal: false
        })
    }

    public onSelectTypePriceAction(isSelectedType: number) {
        if (isSelectedType === 0) {
            this.setState({
                enumTypePriceAction: 1
            })
        } else {
            this.setState({
                enumTypePriceAction: 0
            })
        }
    }

    public onProductVisibleCommand(isVis: boolean) {
        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;

        fetch(`${API.API_POST_CHANGE_VISIBLE_TO_PRODUCT}${this.props.selectedProduct.Id}&isVisible=${isVis}`, {
            headers: {
                'Authorization': AC,
            },
            method: "POST"
        }).then(response => response.json())
            .then(data => {
                if (data.StatusCode === 200) {
                    this.props.onSelectedProduct(data.Body);

                    notification.success({
                        description: '',
                        message: 'Изменена видимость продукта',
                        className: 'notification_item',
                    })

                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_POST_CHANGE_OPTIONS"));
    }

    public handleChange(value: any, option: any) {
         
    }

    public render() {
        return (
            <div className="productChange__CONTAINER">
                <div className="page__TITLE">
                    <span>
                        Создание продукта
                    </span>
                    <div className="close__CONTROL" onClick={() => this.props.onOpenCloseModalNewProduct('closeGetAll')}>
                        <IconBack />
                        <span>вернутся</span>
                    </div>
                </div>
                <div className="newProduct__SECTION">
                    <ul className="t_LINE">
                        <li className="product__ROW_INFO">
                            <div className="l__ICON">
                                <IconFolder />
                            </div>
                            <div className="categories__INFO_P">
                                <p className="category__NAME_T">категория</p>
                                <p className="category__NAME_B">{this.props.selectedSubCategory.Name}</p>

                                {
                                    this.props.selectedProduct.IsVisible ?
                                        <div className="removeProduct__CONTROL isVis done" onClick={() => this.onProductVisibleCommand(false)}>
                                            Опубликовано
                                        </div> :
                                        <div className="removeProduct__CONTROL isVis" onClick={() => this.onProductVisibleCommand(true)}>
                                            Опубликовать ?
                                        </div>
                                }

                                <div className="removeProduct__CONTROL" onClick={() => this.onOpenCloseModalRemoveProductFrame()}>
                                    <IconRemove />
                                </div>
                            </div>

                            {this.props.selectedProduct.Name ?
                                <>
                                    <div className="product__NAME_P">
                                        <div className="product__NAME_ROW">
                                            <div className="input__CONTROL">
                                                <div className="prod__CELL">
                                                    <p className="product__NAME_T">название продукта</p>
                                                </div>
                                                <div className="prod__CELL price">
                                                    <p className="product__NAME_T">цена заготовки</p>
                                                </div>
                                                <div className="prod__CELL price">
                                                    <p className="product__NAME_T">цена на печать</p>
                                                </div>
                                            </div>
                                            <div className="input__CONTROL hideArrow">
                                                <div className="prod__CELL">
                                                    <input type="text"
                                                        value={this.props.selectedProduct.Name}
                                                        onChange={(event: any) => this.props.onValueChangeEditSelectedProductName(event)}
                                                    />
                                                </div>
                                                <div className="prod__CELL price">
                                                    <input type="number"
                                                        placeholder={'Price'}
                                                        value={this.props.selectedProduct.Price}
                                                        onChange={(event: any) => this.props.onValueChangeEditProductPrice(event)}
                                                    />
                                                </div>
                                                <div className="prod__CELL price">
                                                    <input type="number"
                                                        placeholder={'PricePrint'}
                                                        value={this.props.selectedProduct.PricePrint}
                                                        onChange={(event: any) => this.props.onValueChangeEditProductPrintPrice(event)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="button__CONTROL">
                                                <div className="changeProductName__BUTTON" onClick={() => this.onProductChangeCommand('edit')}>Изменить</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product__NAME_P nPadd">
                                        <details className="productImg__DETAILS">
                                            <summary>Превью продукта</summary>
                                            <div className="product__NAME_ROW">
                                                <div className="input__CONTROL">
                                                    <input className="input__DEFAULT" type="file"
                                                        onChange={(event: any) => this.onValueChangeProductImgUrl(event)}
                                                    />
                                                </div>

                                                <div className="button__CONTROL">
                                                    <div className="changeProductName__BUTTON" onClick={() => this.manageImageToProduct('new')}>Добавить картинку</div>
                                                </div>
                                            </div>

                                            {
                                                this.props.selectedProduct.Image ?
                                                    <img className="selectedProduct__Image" src={this.props.selectedProduct.Image.ImageUrl} />
                                                    : null
                                            }
                                        </details>
                                    </div>
                                </> :
                                <div className="product__NAME_P">
                                    <div className="product__NAME_ROW">
                                        <div className="input__CONTROL">
                                            <div className="prod__CELL">
                                                <p className="product__NAME_T">название продукта</p>
                                            </div>
                                            <div className="prod__CELL price">
                                                <p className="product__NAME_T">цена заготовки</p>
                                            </div>
                                            <div className="prod__CELL price">
                                                <p className="product__NAME_T">цена на печать</p>
                                            </div>
                                        </div>

                                        <div className="input__CONTROL hideArrow">
                                            <div className="prod__CELL">
                                                <input type="text"
                                                    value={this.state.productName}
                                                    onChange={(event: any) => this.onValueChangeEditProductName(event)}
                                                />
                                            </div>
                                            <div className="prod__CELL price">
                                                <input type="number"
                                                    value={this.state.productChangeNewPrice}
                                                    placeholder={'цена заготовки'}
                                                    onChange={(event: any) => this.onValueChangeEditProductNewPrice(event)}
                                                />
                                            </div>
                                            <div className="prod__CELL price">
                                                <input type="number"
                                                    value={this.state.productChangeNewPrintPrice}
                                                    placeholder={'цена на печать'}
                                                    onChange={(event: any) => this.onValueChangeEditProductNewPrintPrice(event)}
                                                />
                                            </div>
                                        </div>

                                        <div className="button__CONTROL">
                                            <div className="changeProductName__BUTTON" onClick={() => this.onProductChangeCommand('new')}>Сохранить</div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </li>

                        {
                            this.props.selectedProduct.Name ?
                                <>
                                    <li className="colors__ROW_INFO">
                                        <div className="l__ICON">
                                            <IconPriceRatio />
                                        </div>
                                        <div className="colors__FRAME">
                                            <div className="allColorsInProduct__LIST">
                                                <div className="list__BODY">
                                                    <div className="priceFrame__CONTAINER">

                                                        <div className="row">
                                                            <div className="cell">
                                                                <input className="input__DEFAULT"
                                                                    type="number"
                                                                    value={this.state.rowPriceFrom}
                                                                    onChange={(event: any) => this.onValueChangeRowPriceFrom(event)}
                                                                />
                                                            </div>
                                                            <div className="cell">
                                                                <input className="input__DEFAULT"
                                                                    type="number"
                                                                    value={this.state.rowPriceTo}
                                                                    onChange={(event: any) => this.onValueChangeRowPriceTo(event)}
                                                                />
                                                            </div>
                                                            <div className="cell">
                                                                <input className="input__DEFAULT"
                                                                    type="number"
                                                                    value={this.state.rowPriceCof}
                                                                    onChange={(event: any) => this.onValueChangeRowPriceCof(event)}
                                                                />
                                                            </div>
                                                            <div className="cell last">
                                                                <input className="input__DEFAULT"
                                                                    type="number"
                                                                    value={this.state.rowPricePrintCof}
                                                                    onChange={(event: any) => this.onValueChangeRowPricePrintCof(event)}
                                                                />
                                                            </div>

                                                            <div className="cell cell_controls">
                                                                <div className="add__CELL" onClick={() => this.onCreateRowPriceLvl('new')}>
                                                                    Добавить
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="cell">
                                                                <div className="title_CELL">От </div>
                                                            </div>
                                                            <div className="cell">
                                                                <div className="title_CELL">До </div>
                                                            </div>
                                                            <div className="cell">
                                                                <div className="title_CELL">Цена заготовки (коэффициент)</div>
                                                            </div>
                                                            <div className="cell">
                                                                <div className="title_CELL">Цена на печать (коэффициент)</div>
                                                            </div>
                                                            <div className="cell"></div>
                                                        </div>

                                                        {this.props.selectedProduct.PriceFrames.map((priceFrameListItem: PriceFrame, index: number) =>
                                                            <div className="row" key={index}>
                                                                <div className="cell">
                                                                    <div className="input__DEFAULT">
                                                                        {priceFrameListItem.From}
                                                                    </div>
                                                                </div>
                                                                <div className="cell">
                                                                    <div className="input__DEFAULT">
                                                                        {priceFrameListItem.To}
                                                                    </div>
                                                                </div>
                                                                <div className="cell">
                                                                    <div className="input__DEFAULT">
                                                                        {priceFrameListItem.PriceCoefficient}
                                                                        <Tooltip placement="topRight" title={priceFrameListItem.Price}>
                                                                            <span className="pItem">{priceFrameListItem.Price.toFixed(2)} за шт</span>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                                <div className="cell last">
                                                                    <div className="input__DEFAULT">
                                                                        {priceFrameListItem.PricePrintCoefficient}

                                                                        <Tooltip placement="topRight" title={priceFrameListItem.PricePrint}>
                                                                            <span className="pItem">{priceFrameListItem.PricePrint.toFixed(2)} за шт</span>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                                <div className="cell cell_controls">
                                                                    <div className="edit__CELL" onClick={() => {
                                                                        this.setState({
                                                                            selectedPriceLvl: priceFrameListItem
                                                                        })
                                                                    }}>
                                                                        <EditOutlined />
                                                                    </div>
                                                                    <div className="remove__CELL" onClick={() => this.onSelectedPriceFrameItem(priceFrameListItem)}>
                                                                        Удалить
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </li>

                                    <li className="colors__ROW_INFO">
                                        <div className="l__ICON">
                                            <IconSettings />
                                        </div>
                                        <div className="colors__FRAME">
                                            <div className="allColors__LIST">
                                                <div className="list__BODY">
                                                    {
                                                        this.state.optionTypeList ? this.state.optionTypeList.filter(x => !x.IsColor).map((option: Option, index: number) => {
                                                            if (option.Options.length > 0) {


                                                                return (
                                                                    <details className="details__optionItem border__details" key={index}>
                                                                        <summary>{option.Name ? option.Name : 'Error - colorList[color.name]'}</summary>

                                                                        <div className="detailsContent__optionList">
                                                                            {option.Options ? option.Options.map((subOption: Option, key: number) =>
                                                                                <div className="color__ITEM" key={key} onClick={() => this.onSelectedOption(subOption)}>
                                                                                    <span className="color_CI"></span>
                                                                                    {subOption.Name}
                                                                                </div>
                                                                            ) : null}
                                                                        </div>

                                                                    </details>
                                                                )
                                                            }
                                                        }
                                                        ) : null
                                                    }

                                                </div>
                                                {
                                                    this.state.isSelectedOption && this.state.selectedOption.Name ?
                                                        <div className="addColorToProduct__MODAL">
                                                            <div className="modal__BODY card__DEFAULT">
                                                                <div className="card__HEADER">
                                                                    <p>Добавление опции</p>
                                                                    <small>
                                                                        картинка для продукта <b className="pName">{this.props.selectedProduct.Name}</b>,
                                                                    опция - <b>{this.state.selectedOption.Name}</b>
                                                                    </small>
                                                                </div>

                                                                <div className="imgURL__WRAPPER">
                                                                    <div className="iu__WRAPPER_TITLE">
                                                                        Ценовой коэффициент опции
                                                                    </div>
                                                                    <div className="swich__CONTROL">
                                                                        <input className="input__DEFAULT"
                                                                            type="number"
                                                                            value={this.state.optionPriceRatio}
                                                                            onChange={(event: any) => this.onValueChangeOptionPriceRatio(event)}
                                                                        />
                                                                        <div className="sCase__CONTAINER" onClick={() => this.onSelectTypePriceAction(this.state.enumTypePriceAction)}>
                                                                            {this.state.enumTypePriceAction ?
                                                                                <div className="sCase__ITEM">*</div> :
                                                                                <div className="sCase__ITEM pluss">+</div>
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                    <div className="input__ICON">
                                                                        <IconPriceRatio />
                                                                    </div>
                                                                </div>

                                                                <div className="imgURL__WRAPPER">
                                                                    <div className="iu__WRAPPER_TITLE">
                                                                        Картинка опции
                                                                    </div>
                                                                    <input className="input__DEFAULT" type="file"
                                                                        onChange={(event: any) => this.onValueChangeOptionUrl(event)}
                                                                    />
                                                                    <div className="input__ICON">
                                                                        <IconPhoto />
                                                                    </div>
                                                                </div>

                                                                <div className="card__FOOTER">
                                                                    <div className="f_RIGHT">
                                                                        <div className="f__CONTROL back" onClick={() => this.onCloseModalProductChange()}>Отменить</div>
                                                                        {this.state.selectedOptionImgUrl ? <div className="f__CONTROL" onClick={() => this.manageColorToProduct('newOption')}>Добавить</div> : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                }

                                            </div>

                                            <div className="allColorsInProduct__LIST">
                                                <div className="list__BODY">
                                                    {

                                                        (this.props.selectedProduct && this.props.selectedProduct.TypeOptions) ? this.props.selectedProduct.TypeOptions.map((optionTypeItem: TypeOption, index: number) =>
                                                            <div className="color__ITEM_iN2" key={index}>
                                                                {optionTypeItem.Options ? optionTypeItem.Options.map((optionItem2: Option, i: number) =>
                                                                    <div key={i}>


                                                                        {optionItem2.ProductOptions.map((optionItem: ProductOption, x: number) =>
                                                                            <div className="color__ITEM_iN" key={x}>

                                                                                {optionTypeItem.Name} - {optionItem2.Name ? optionItem2.Name : 'optionItemName'}

                                                                                {optionItem.Images.length > 0 ?
                                                                                    <div className="imgUrl">
                                                                                        <a href={optionItem.Images[0].ImageUrl} target="_blanc" >{optionItem.Images[0].ImageUrl}</a>
                                                                                    </div> : null
                                                                                }

                                                                                <div className="priceCoefficient__frame">
                                                                                    {optionItem.TypePriceAction ? '* ' : '+ '}
                                                                                    {optionItem.PriceCoefficient}
                                                                                    <span> коэф.</span>
                                                                                </div>

                                                                                <div className="itemIN__CONTROLS">
                                                                                    <div className="control__ITEM i_remove" onClick={() => this.removeColorInProduct(optionItem)}>
                                                                                        <IconRemove />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ) : null}

                                                            </div>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="colors__ROW_INFO">
                                        <div className="l__ICON">
                                            <IconSettings />
                                        </div>
                                        <div className="colors__FRAME">
                                            <div className="allColors__LIST">
                                                <div className="list__BODY">
                                                    {
                                                        this.props.colorList ? this.props.colorList.map((color: Option, index: number) =>
                                                            <div className="color__ITEM" key={index} onClick={() => this.onSelectedColor(color)}>
                                                                <span className="color_CI"></span>
                                                                {color.Name ? color.Name : 'ColorName'}
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>
                                                {
                                                    this.state.isSelectedColor && this.state.selectedColor.Name ?
                                                        <div className="addColorToProduct__MODAL">
                                                            <div className="modal__BODY card__DEFAULT">
                                                                <div className="card__HEADER">
                                                                    <p>Добавление цвета</p>
                                                                    <small>
                                                                        картинка для продукта <b className="pName">{this.props.selectedProduct.Name}</b>,
                                                                    цвет - <b>{this.state.selectedColor.Name}</b>
                                                                    </small>
                                                                </div>

                                                                <div className="imgURL__WRAPPER">
                                                                    <div className="iu__WRAPPER_TITLE">
                                                                        Ценовой коэффициент опции
                                                                    </div>
                                                                    <div className="swich__CONTROL">
                                                                        <input className="input__DEFAULT"
                                                                            type="number"
                                                                            value={this.state.optionPriceRatio}
                                                                            onChange={(event: any) => this.onValueChangeOptionPriceRatio(event)}
                                                                        />
                                                                        <div className="sCase__CONTAINER" onClick={() => this.onSelectTypePriceAction(this.state.enumTypePriceAction)}>
                                                                            {this.state.enumTypePriceAction ?
                                                                                <div className="sCase__ITEM">*</div> :
                                                                                <div className="sCase__ITEM pluss">+</div>
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                    <div className="input__ICON">
                                                                        <IconPriceRatio />
                                                                    </div>
                                                                </div>

                                                                <div className="imgURL__WRAPPER">
                                                                    <div className="iu__WRAPPER_TITLE">
                                                                        Картинка опции
                                                                    </div>
                                                                    <input className="input__DEFAULT" type="file"
                                                                        onChange={(event: any) => this.onValueChangeColorUrl(event)}
                                                                    />
                                                                    <div className="input__ICON">
                                                                        <IconPhoto />
                                                                    </div>
                                                                </div>

                                                                <div className="card__FOOTER">
                                                                    <div className="f_RIGHT">
                                                                        <div className="f__CONTROL back" onClick={() => this.onCloseModalProductChange()}>Отменить</div>
                                                                        {this.state.selectedColorImgUrl ? <div className="f__CONTROL" onClick={() => this.manageColorToProduct('new')}>Добавить</div> : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                }

                                            </div>

                                            <div className="allColorsInProduct__LIST">
                                                <div className="list__BODY">
                                                    {

                                                        (this.props.selectedProduct && this.props.selectedProduct.Colors) ? this.props.selectedProduct.Colors.map((optionItem: ProductOption, index: number) =>
                                                            <div className="color__ITEM_iN" key={index}>

                                                                {optionItem.Option.Name ? optionItem.Option.Name : 'OptionName'}

                                                                {optionItem.Images.length > 0 ?
                                                                    <div className="imgUrl">
                                                                        <a href={optionItem.Images[0].ImageUrl} target="_blanc" >{optionItem.Images[0].ImageUrl}</a>
                                                                    </div> : null
                                                                }


                                                                <div className="priceCoefficient__frame">
                                                                    {optionItem.TypePriceAction ? '* ' : '+ '}
                                                                    {optionItem.PriceCoefficient}
                                                                    <span> коэф.</span>
                                                                </div>

                                                                <div className="itemIN__CONTROLS">
                                                                    <div className="control__ITEM i_remove" onClick={() => this.removeColorInProduct(optionItem)}>
                                                                        <IconRemove />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </li>
                                </> : null
                        }
                    </ul>
                </div>

                {
                    this.state.isSelectedPriceFrameModal &&
                    <div className="editControl__MODAL">
                        <div className="modal__FRAME">
                            <div className="modal__CLOSE" onClick={() => this.onCloseModalRemovePriceFrame()}>
                                <IconClose />
                            </div>
                            <div className="modal__HEADER">
                                Удаление количественнои рамки
                            </div>
                            <div className="modal__BODY">
                                <h3>
                                    {this.state.selectedPriceFrame.From} - {this.state.selectedPriceFrame.To}
                                </h3>
                                <p className="hide"> для продутка {this.state.selectedProduct.Name}</p>
                                <div className="default__CONTROL" onClick={(event: any) => this.onCreateRowPriceLvl('remove')}>Удалить</div>
                            </div>
                        </div>
                    </div>
                }

                {
                    this.state.isSelectedRemoveProductModal &&

                    <div className="editControl__MODAL">
                        <div className="modal__FRAME">
                            <div className="modal__CLOSE" onClick={() => this.onOpenCloseModalRemoveProductFrame()}>
                                <IconClose />
                            </div>

                            <div className="modal__HEADER">Удаление продукта</div>
                            <div className="modal__BODY">
                                <h3>
                                    {this.props.selectedProduct.Name}
                                </h3>

                                <div className="default__CONTROL" onClick={(event: any) => this.onProductRemoveCommand('remove')}>Удалить</div>
                            </div>
                        </div>
                    </div>
                }

                <Modal
                    className="priceLvl__MODAL"
                    okText={'Изменить'}
                    cancelText={'Отмена'}
                    title="Ценовой уровень"
                    visible={this.state.selectedPriceLvl && this.state.selectedPriceLvl.Id !== 0}
                    onOk={() => { }}
                    footer={false}
                    onCancel={() => {
                        this.setState({
                            selectedPriceLvl: new PriceFrame()
                        })
                    }}
                >
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            from: this.state.selectedPriceLvl.From,
                            to: this.state.selectedPriceLvl.To,
                            priceCoefficient: this.state.selectedPriceLvl.PriceCoefficient,
                            pricePrintCoefficient: this.state.selectedPriceLvl.PricePrintCoefficient
                        }}
                        onSubmit={(values) => {
                             
                            const data: PriceFrame = {
                                ...this.state.selectedPriceLvl,
                                To: values.to,
                                From: values.from,
                                PriceCoefficient: values.priceCoefficient,
                                PricePrintCoefficient: values.pricePrintCoefficient,
                            };

                            this.onEditRowPriceLvl(data)
                             
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <div className="cell">
                                    <label>От</label>
                                    <input className="input__DEFAULT"
                                        type="number"
                                        name="from"
                                        value={formik.values.from}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="cell">
                                    <label>До</label>
                                    <input className="input__DEFAULT"
                                        type="number"
                                        name="to"
                                        value={formik.values.to}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="cell">
                                    <label>цена заготовки (коэффициент)</label>
                                    <input className="input__DEFAULT"
                                        type="number"
                                        name="priceCoefficient"
                                        value={formik.values.priceCoefficient}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="cell last">
                                    <label>цена на печать (коэффициент)</label>
                                    <input className="input__DEFAULT"
                                        type="number"
                                        name="pricePrintCoefficient"
                                        value={formik.values.pricePrintCoefficient}
                                        onChange={formik.handleChange}
                                    />
                                </div>

                                <div className="formControl" style={{
                                    padding: '0 5px',
                                    display: "flex",
                                    justifyContent: "flex-end"
                                }}>
                                    <Button type="primary" className={'submitButton2'} htmlType="submit">Изменить</Button>

                                    { /* <button className="submitButton" type="submit">Send</button> disabled={formik.isSubmitting}*/}
                                </div>
                            </Form>
                        )}
                    </Formik>


                </Modal>
            </div >
        )
    }
}