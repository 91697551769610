import React from "react";
import { IconClose } from "../../../icons/icons";
import * as API from "../../../constants/api.constants";
import { getCookie } from "../../../helpers/cookies.helper";

export class EditProductView extends React.Component<any, any>{
    constructor(props: any) {
        super(props);

        this.state = {
            productName: this.props.selectedProduct.name
        }
    }

    public onProductChangeCommand(command) {

        let token: string = getCookie('token');
        let AC = 'Bearer ' + token;
        let changeBodyProduct: any;

        switch (command) {
            case 'edit': {
                changeBodyProduct = {
                    Name: this.state.productName,
                    Id: this.props.selectedProduct.id,
                    CategoryId: this.props.selectedProduct.categoryId
                }
                break;
            }

            case 'remove': {
                changeBodyProduct = {
                    Deleted: true,
                    Id: this.props.selectedProduct.id
                }
                break;
            }
        }

        fetch(API.API_POST_CHANGE_PRODUCT, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AC,
            },
            method: "POST",
            body: JSON.stringify(changeBodyProduct),
        }).then(response => response.json())
            .then(data => {
                if (data.statusCode === 200) {
                    this.props.onChangeProductList(this.props.selectedProduct.categoryId);
                    this.props.onOpenCloseModalEditProduct();
                } else {
                    console.log(data);
                }
            }).catch(() => console.log("Error - API_POST_CHANGE_CATEGORY"));

    }

    public onValueChangeEditProductName(event: any) {
        this.setState({
            productName: event.target.value
        })
    }

    public render() {
        return (
            <div className="newControl__MODAL">
                <div className="modal__FRAME">
                    <div className="page__TITLE">
                        <span>Редактирование</span>
                        <div className="add__CONTROL" onClick={() => this.onProductChangeCommand('remove')}>удалить</div>
                    </div>

                    <div className="modal__CLOSE" onClick={() => this.props.onOpenCloseModalEditProduct()}>
                        <IconClose />
                    </div>
                    <br />
                    <div className="modal__BODY">
                        <input type="text"
                            placeholder="Название"
                            value={this.state.productName}
                            onChange={(event: any) => this.onValueChangeEditProductName(event)}
                        />

                        <div className="new__CONTROL"
                            onClick={(event: any) => this.onProductChangeCommand('edit')}>Сохранить</div>
                    </div>
                </div>
            </div>
        )
    }
}