import { EntityBase } from "./entityBase"
import { ProductOption } from "./productOption";
import { Category } from "./category";
import { Image } from "./image";
import { PriceFrame } from "./price.frame";
import { TypeOption } from "./type.option";

export class Product extends EntityBase {
    public Name: string = '';
    public CategoryId: number = 0;
    public Category: Category = new Category;
    public ProductOptions: Array<ProductOption> = [];
    public Image: Image | undefined = undefined;
    public ImageId: number | undefined;
    public Colors: Array<ProductOption> = [];
    public Sizes: Array<ProductOption> = [];
    public PricePrint: number = 0;
    public Price: number = 0;
    public PriceFrames: Array<PriceFrame> = [];
    public TypeOptions: Array<TypeOption> = [];
    public IsVisible: boolean = false;
}