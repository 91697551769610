import React from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router";
import * as routes from "../src/common/routes";
import { IApplicationState } from "./reducers/application.state";
import { SignIn } from "./views/auth/signin";
import MasterView from "./views/master.view";

import { ProtectedRoute } from "./helpers/protected.route";
import { AuthenticationState } from './reducers/authentication.slice'

{ /*
 export type PrivateRouteProps = typeof React.Component & boolean & any;

export const PrivateRoute = (props: PrivateRouteProps) => {
    let AC = localStorage.getItem('AC');

    if (AC) {
        return (<Route path={props.path} component={props.component} />);
    } else {
        return (<Redirect from={props.path} to={routes.AuthView} />);
    }
};

export class Routing extends React.Component<any, any>{
    render() {
        return (
            <>
                <Switch>
                    <Redirect exact from="/" to={routes.AuthView} />
                    <Route path={routes.AuthView} component={SignIn}></Route>
                    <PrivateRoute path={routes.MasterView} component={MasterView} />
                </Switch>
            </>
        )
    }
}
 */}

interface IRouting {
    onEnter: void;
    path: string
}

const Routing: React.FC<IRouting> = (props) => {
     
    const AuthenticationState = useSelector<IApplicationState, AuthenticationState>((state) => state.authentication);

    if (AuthenticationState.netUid && AuthenticationState.netUid !== '') {
          
        return (
            <Switch>
                <Redirect exact={true} from={'/'} to={`${routes.MasterView}`} />
                <ProtectedRoute
                    path={`${routes.MasterView}`}
                    authenticated={!!AuthenticationState.netUid}
                    component={MasterView}
                />
            </Switch>
        )
    }
    else {
        return (
            <Switch>
                <Redirect
                    exact
                    from={'/'}
                    to={`${routes.AuthView}`} />
                <Route path={routes.AuthView} component={SignIn} />
            </Switch>
        )
    }
}

export default Routing;