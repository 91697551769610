import { Store } from 'redux'
import * as routes from "./../constants/routes.constants";
import { push } from 'react-router-redux';
import { getCookie } from './cookies.helper';
import { TokenHelper } from './token.helper';
import { authenticationActions } from '../reducers/authentication.slice';


//export function AppInit(store: Store<any>) {
//    if (document.URL.indexOf(routes.APP_URI) === -1) {
//        store.dispatch(push(routes.APP_URI));
//    }
//}

export function AppInit(store: Store<any>) {
    const token: string = getCookie('token');
    const refresh: string = getCookie('refresh');

    if (token) {
        const parsedToken = TokenHelper.parseJwt(token);

        if (document.URL.indexOf(routes.APP_URI) === -1) {
            store.dispatch(push(routes.APP_URI));
        }

        store.dispatch(
            authenticationActions.requestTokenSuccess({
                name: parsedToken.unique_name,
                email: parsedToken.email,
                netUid: parsedToken.ID,
                role: parsedToken.role,
                token: token,
                expires: parsedToken.exp * 1000
            })
        )

         

    } else {
        if (document.URL.indexOf(routes.APP_URI) !== -1) {
            store.dispatch(authenticationActions.logoutSuccess)
        }
    }
}