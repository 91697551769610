import { EntityBase } from "./entityBase";
import { Product } from "./product";

export class PriceFrame  extends EntityBase {
    public From: number = 1;
    public To: number = 1;
    public PriceCoefficient: number = 1;
    public PricePrintCoefficient: number = 1;
    public ProductId: number = 0;
    public Product: Product | undefined;
    public PricePrint: number = 0;
    public Price: number = 0;
}